import axios from 'axios';
import constants from '../constants';

async function updateSubscriber(entryId, formData) {
  const data = prepareData(formData);
  try {
    const response = await axios.put(`${constants.wpUrl}/gf/v2/entries/${entryId}`, data, {
      headers: {
        authorization: constants.wpAuth,
      },
    });
    return {
      data: response.data,
      keyMessage: 'subscribe.successUpdate',
    };
  } catch (error) {
    return {
      keyMessage: 'errors.server',
    };
  }
}

function prepareData(formData) {
  return {
    1.3: formData.firstName,
    1.6: formData.lastName,
    2: formData.email,
    6: formData.currentUrl,
    7: formData.previousUrl,
    9: formData.userLang,
  };
}

export default updateSubscriber;
