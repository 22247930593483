import axios from 'axios';
import constants from '../constants';

async function removeSubscriberEmail(subscribeId) {
  const entriesResponse = await axios.delete(`${constants.wpUrl}/gf/v2/entries/${subscribeId}`, {
    headers: {
      authorization: constants.wpAuth,
    },
  });
  return entriesResponse.data;
}

export default removeSubscriberEmail;
