import axios from 'axios';
import constants from '../constants';

export const propertyEnquiryForm = 'propertyEnquiryForm';
export const contactForm = 'contactForm';
export const realEstateSignUpForm = 'realEstateSignUpForm';
export const homeSellersForm = 'homeSellersForm';
export const newsletterForm = 'newsletterForm';
export const receiveRecommendationsForm = 'receiveRecommendationsForm';

async function postContactForm(formData, type) {
  const formParams = prepareDataForForm(formData, type);
  const entryResponse = await axios.post(`${constants.apiUrl}/submission-form`, formParams);
  return {
    entry: entryResponse.data,
  };
}

function prepareDataForForm(formData, type) {
  if (type === propertyEnquiryForm) {
    return prepareDataForPropertyEnquiryForm(formData);
  } else if (type === contactForm) {
    return prepareDataForContactForm(formData);
  } else if (type === realEstateSignUpForm) {
    return prepareDataForRealEstateSignUpForm(formData);
  } else if (type === homeSellersForm) {
    return prepareDataForHomeSellersForm(formData);
  } else if (type === newsletterForm) {
    return prepareDataForNewsletter(formData);
  } else if (type === receiveRecommendationsForm) {
    return prepareDataForRecommendationForm(formData);
  }
}

function prepareDataForPropertyEnquiryForm(formData) {
  return {
    formId: 41,
    fields: {
      input_1_3: formData.firstName,
      input_1_6: formData.lastName,
      input_2: formData.email,
      input_3: formData.message,
      input_4: formData.propertyReference,
      input_5: formData.phone,
      input_6: formData.propertyName,
      input_7: formData.propertyURL,
      input_9: formData.agency,
      input_10: formData.contactMethod,
      input_11: formData.preferredTime,
    },
  };
}

function prepareDataForContactForm(formData) {
  return {
    formId: 42,
    fields: {
      input_1_3: formData.firstName,
      input_1_6: formData.lastName,
      input_2: formData.email,
      input_3: formData.message,
      input_5: formData.phone,
      input_6: formData.currentUrl,
      input_7: formData.previousUrl,
    },
  };
}

function prepareDataForRealEstateSignUpForm(formData) {
  return {
    formId: 44,
    fields: {
      input_1_3: formData.firstName,
      input_1_6: formData.lastName,
      input_2: formData.email,
      input_3: formData.message,
      input_5: formData.phone,
      input_6: formData.company,
    },
  };
}

function prepareDataForHomeSellersForm(formData) {
  return {
    formId: 43,
    fields: {
      input_1_3: formData.firstName,
      input_1_6: formData.lastName,
      input_2: formData.email,
      input_3: formData.message,
      input_5: formData.phone,
    },
  };
}

function prepareDataForNewsletter(formData) {
  return {
    formId: 45,
    fields: {
      input_2: formData.email,
    },
  };
}

function prepareDataForRecommendationForm(formData) {
  return {
    formId: 46,
    fields: {
      input_1_3: formData.firstName,
      input_1_6: formData.lastName,
      input_2: formData.email,
      input_6: formData.currentUrl,
      input_7: formData.previousUrl,
      input_9: formData.userLang,
    },
  };
}

export default postContactForm;
