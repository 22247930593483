import React, { useContext, useState, useEffect } from 'react';
import Translate from '../sharedComponents/Translate';
import FormSender from '../sectionComponents/FormSender';
import NameFields from '../sharedComponents/NameFields';
import postSubscribeNewProperties from '../../api/postSubscribeNewProperties';
import updateSubscriber from '../../api/updateSubscriber';
import { Button, Modal } from 'react-bootstrap';
import { AppContext } from '../../App.context';
import { translate } from '../../data/languages/languageUtils';
import { faCaretDown, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import MultiSelectDropdown from './../sharedComponents/MultiSelectDropdown';
import Select from './../sharedComponents/Select';
import useFilters from '../../hooks/useFilters';
import MinMaxSelect from './../sharedComponents/MixMaxSelect';

export default function SubscribeAlerts({ buttonTextKey, ghostStyle, textButton }) {
  const [show, setShow] = useState(false);
  const [filterTexts, setFilterTexts] = useState([]);
  const [subscriber, setSubscriber] = useState(null);
  const [updateSubscribe, setUpdateSubscribe] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    appState: { userLang, previousUrl, currentUrl, filters },
  } = useContext(AppContext);
  const { getTexts, bedroomsOptions, pricesOptions, filtersSubscribe, getFiltersFromUrl } =
    useFilters(userLang);
  const [formState, setFormState] = useState(filtersSubscribe);

  useEffect(() => {
    if (show) {
      const filtersUrl = getFiltersFromUrl(currentUrl);
      setFormState({
        propertyFeatures: filtersUrl.propertyFeatures || [],
        propertyTypes: filtersUrl.propertyTypes || [],
        propertyRegions: filtersUrl.propertyRegions || [],
        propertyLocations: filtersUrl.propertyLocations || [],
        bedroomsMin: filtersUrl?.bedrooms?.min || 0,
        price: filtersUrl?.price || {},
      });
    } else {
      //reset state
      setFormState(filtersSubscribe);
      setSubscriber(null);
      setUpdateSubscribe(false);
      setSuccessUpdate(false);
      setSaving(false);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const texts = getTexts(formState, filters, userLang);
      setFilterTexts(texts);
    }
  }, [formState]);

  function showOptionsSelected(selectedFeatures, userLang, keyText) {
    let text = translate(keyText, userLang);
    if (selectedFeatures && selectedFeatures.length) {
      if (selectedFeatures.length === 1) {
        text = selectedFeatures[0].name;
      } else {
        text = selectedFeatures.length + ' ' + translate('home.filters.selected', userLang);
      }
    }
    return text;
  }

  function getSelectedOptions(allItems, itemsSelected) {
    return allItems && allItems.length > 0 && itemsSelected && itemsSelected.length > 0
      ? allItems?.filter((propertyFeature) =>
          itemsSelected.some((selectedId) => propertyFeature.id == selectedId),
        )
      : [];
  }

  function getNewUrl() {
    const filtersUrl = getFiltersFromUrl(currentUrl);
    let filtersSelected = {
      ...filtersUrl,
      ...{
        bedrooms: { min: parseInt(formState.bedroomsMin) },
        price: formState.price,
        propertyFeatures: formState.propertyFeatures,
        propertyTypes: formState.propertyTypes,
        propertyRegions: formState.propertyRegions,
        propertyLocations: formState.propertyLocations,
      },
    };
    if (formState.bedroomsMin === 0) {
      delete filtersSelected.bedrooms;
    }
    if (formState.priceMin === 0) {
      delete filtersSelected.price;
    }
    if (formState.propertyFeatures.length === 0) {
      delete filtersSelected.propertyFeatures;
    }
    if (formState.propertyTypes.length === 0) {
      delete filtersSelected.propertyTypes;
    }
    if (formState.propertyRegions.length === 0) {
      delete filtersSelected.propertyRegions;
    }
    if (formState.propertyLocations.length === 0) {
      delete filtersSelected.propertyLocations;
    }
    return `${window.location.origin}/properties?filter=${encodeURIComponent(
      JSON.stringify(filtersSelected),
    )}`;
  }

  async function submitForm(formValues) {
    if (subscriber) {
      setSaving(true);
      try {
        const data = { ...formValues, ...{ previousUrl, currentUrl: getNewUrl(), userLang } };
        const resp = await updateSubscriber(subscriber.id, data);
        setSuccessUpdate(true);
        setSaving(false);
        return resp;
      } catch (e) {
        setSaving(false);
        return e;
      }
    } else {
      try {
        const resp = await postSubscribeNewProperties({
          ...formValues,
          ...{ previousUrl, currentUrl: getNewUrl(), userLang },
        });
        if (resp.keyMessage === 'subscribe.alreadySubscribe') {
          setUpdateSubscribe(true);
        }
        if (resp.subscribe) {
          setSubscriber(resp.subscribe);
        }
        return resp;
      } catch (e) {
        return e;
      }
    }
  }

  return (
    <>
      <Button
        className={`${ghostStyle ? 'btn-ghost' : 'btn-default'}`}
        onClick={() => setShow(true)}
      >
        {textButton ? textButton : <Translate textKey={buttonTextKey} />}
      </Button>
      {show && (
        <Modal show={show} onHide={() => setShow(false)} centered size="lg">
          <Modal.Header>
            <div>
              <h5>
                <Translate textKey={'forms.subscribeTitle'} />
              </h5>
              {filterTexts.map((filter, index) => (
                <div key={index}>{filter.text}</div>
              ))}
            </div>
          </Modal.Header>
          <Modal.Body>
            <FormSender
              onSubmit={(formValues) => submitForm(formValues)}
              firstValues={{ firstName: '', lastName: '', email: '' }}
              formContent={({ formValues, setFormValue, btnIcon, feedbackComponent }) => {
                return (
                  <div className="subscribe-alerts">
                    <NameFields
                      formValues={formValues}
                      setFormValue={setFormValue}
                      userLang={userLang}
                    />
                    <div className="rld-single-input">
                      <input
                        type="email"
                        placeholder={translate('footer.yourEmail', userLang)}
                        value={formValues.email}
                        onChange={setFormValue('email')}
                        required
                      />
                    </div>
                    <div className="wrapper-input">
                      <MultiSelectDropdown
                        placeholder={showOptionsSelected(
                          getSelectedOptions(filters.propertyTypes, formState.propertyTypes),
                          userLang,
                          'home.filters.propertyType',
                        )}
                        icon={faCaretDown}
                        options={filters.propertyTypes}
                        selectedOptions={getSelectedOptions(
                          filters.propertyTypes,
                          formState.propertyTypes,
                        )}
                        onChange={(value) =>
                          setFormState({ ...formState, propertyTypes: value.map((val) => val.id) })
                        }
                      />
                    </div>
                    <div className="wrapper-input">
                      <MultiSelectDropdown
                        placeholder={showOptionsSelected(
                          getSelectedOptions(filters.propertyRegions, formState.propertyRegions),
                          userLang,
                          'home.filters.searchByRegion',
                        )}
                        icon={faMapMarkerAlt}
                        options={filters.propertyRegions}
                        selectedOptions={getSelectedOptions(
                          filters.propertyRegions,
                          formState.propertyRegions,
                        )}
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            propertyRegions: value.map((val) => val.id),
                          })
                        }
                      />
                    </div>
                    <div className="wrapper-input">
                      <MultiSelectDropdown
                        placeholder={showOptionsSelected(
                          getSelectedOptions(
                            filters.propertyLocations,
                            formState.propertyLocations,
                          ),
                          userLang,
                          'home.filters.searchByLocation',
                        )}
                        icon={faMapMarkerAlt}
                        options={filters.propertyLocations}
                        selectedOptions={getSelectedOptions(
                          filters.propertyLocations,
                          formState.propertyLocations,
                        )}
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            propertyLocations: value.map((val) => val.id),
                          })
                        }
                      />
                    </div>
                    <div className="wrapper-input">
                      <MultiSelectDropdown
                        placeholder={showOptionsSelected(
                          getSelectedOptions(filters.propertyFeatures, formState.propertyFeatures),
                          userLang,
                          'home.filters.propertyFeatures',
                        )}
                        icon={faCaretDown}
                        options={filters.propertyFeatures}
                        selectedOptions={getSelectedOptions(
                          filters.propertyFeatures,
                          formState.propertyFeatures,
                        )}
                        onChange={(value) =>
                          setFormState({
                            ...formState,
                            propertyFeatures: value.map((val) => val.id),
                          })
                        }
                      />
                    </div>
                    <div className="wrapper-input">
                      <Select
                        options={bedroomsOptions}
                        value={formState.bedroomsMin}
                        label={translate('home.filters.minBedrooms', userLang)}
                        onChange={(value) => {
                          setFormState((prevState) => ({
                            ...prevState,
                            bedroomsMin: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="wrapper-input">
                      <MinMaxSelect
                        options={pricesOptions}
                        min={formState.price?.min}
                        max={formState.price?.max}
                        minLabel={translate('home.filters.minPrice', userLang)}
                        maxLabel={translate('home.filters.maxPrice', userLang)}
                        onChange={(value) => setFormState({ ...formState, price: value })}
                      />
                    </div>
                    <div className="button-with-feedback">
                      {feedbackComponent}
                      {!updateSubscribe && (
                        <button className="btn btn-yellow">
                          <Translate textKey={'forms.subscribeButton'} />
                          {btnIcon}
                        </button>
                      )}
                      {updateSubscribe && (
                        <div className="confirm-buttons">
                          {!saving && !successUpdate && (
                            <button className="btn cancel" onClick={() => setShow(false)}>
                              <Translate textKey={'subscribe.cancelUpdate'} />
                            </button>
                          )}
                          <button className="btn btn-yellow" disabled={successUpdate}>
                            <Translate textKey={'subscribe.confirmUpdate'} />
                            {successUpdate && btnIcon}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
