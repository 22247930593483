export function buildRequirements(lang, filter, maxResults = 24) {
  const skip = filter.page ? filter.page * maxResults - maxResults : filter.skip || 0;
  const requirements = {
    lang,
    count: filter.count || maxResults,
  };
  if (filter.reference) {
    requirements.reference = filter.reference;
    requirements.skip = skip;
    requirements.order = filter.order;
    requirements.orderDirection = filter.orderDirection;
  } else {
    requirements.locationIds = filter.propertyLocations?.length
      ? filter.propertyLocations
      : undefined;
    requirements.typeIds = filter.propertyTypes?.length ? filter.propertyTypes : undefined;
    requirements.price = filter.price;
    requirements.bedrooms = filter.bedrooms;
    requirements.regionIds = filter.propertyRegions?.length ? filter.propertyRegions : undefined;
    requirements.featureIds = filter.propertyFeatures?.length ? filter.propertyFeatures : undefined;
    requirements.skip = skip;
    requirements.order = filter.order;
    requirements.orderDirection = filter.orderDirection;
  }
  return requirements;
}
