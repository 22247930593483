import React, { useContext } from 'react';
import HeaderFilters from '../globalComponents/HeaderFilters';
import PageHeader from '../globalComponents/PageHeader';
import Footer from '../globalComponents/Footer';
import Translate from '../sharedComponents/Translate';
import { AppContext } from '../../App.context';
import { translate } from '../../data/languages/languageUtils';
import postContactForm, { homeSellersForm, realEstateSignUpForm } from '../../api/postContactForm';
import FormSender from './FormSender';

function ContactForm({ titleKey, imageUrl, type }) {
  const {
    appState: { userLang, previousUrl, currentUrl },
  } = useContext(AppContext);
  return (
    <div>
      <HeaderFilters sticky={true} />
      <div className="page-content">
        <PageHeader titleKey={titleKey} imageUrl={imageUrl} />
        <div className="contact-area pd-top-100 pd-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <FormSender
                  className="contact-form-wrap contact-form-bg"
                  onSubmit={(formValues) =>
                    postContactForm({ ...formValues, previousUrl, currentUrl }, type)
                  }
                  firstValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                    company: '',
                  }}
                  formContent={({ formValues, setFormValue, btnIcon, feedbackComponent }) => {
                    return (
                      <>
                        <h4>
                          <Translate textKey="forms.contactNow" />
                        </h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="rld-single-input">
                              <input
                                type="text"
                                placeholder={translate('forms.firstName', userLang)}
                                value={formValues.firstName}
                                onChange={setFormValue('firstName')}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="rld-single-input">
                              <input
                                type="text"
                                placeholder={translate('forms.lastName', userLang)}
                                value={formValues.lastName}
                                onChange={setFormValue('lastName')}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        {type === realEstateSignUpForm && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="rld-single-input">
                                <input
                                  type="text"
                                  placeholder={translate('forms.company', userLang)}
                                  value={formValues.company}
                                  onChange={setFormValue('company')}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-12">
                            <div className="rld-single-input">
                              <input
                                type="email"
                                placeholder={translate('forms.email', userLang)}
                                value={formValues.email}
                                onChange={setFormValue('email')}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="rld-single-input">
                              <input
                                type="text"
                                placeholder={translate('forms.phone', userLang)}
                                value={formValues.phone}
                                onChange={setFormValue('phone')}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="rld-single-input">
                              <textarea
                                rows={10}
                                placeholder={getPlaceholder(type, userLang)}
                                value={formValues.message}
                                onChange={setFormValue('message')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="btn-wrap text-center">
                          <div className="button-with-feedback">
                            <button className="btn btn-yellow">
                              <Translate textKey="forms.submit" />
                              {btnIcon}
                            </button>
                            {feedbackComponent}
                          </div>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
            <div className="row pd-top-92">
              <div className="col-md-12 mb-5">
                <h3>Helen Cummins</h3>
                <h4>Mallorca Property Network</h4>
              </div>
              <div className="col-sm-4">
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-phone" />
                    <Translate textKey="forms.callUs" />
                  </p>
                  <a href="tel:+34971708888">
                    <h5>+34 971 708 888</h5>
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-envelope" />
                    <Translate textKey="forms.haveAnyQuestion" />
                  </p>
                  <a href="mailto:office@mallorcaproperty.net">
                    <h5>office@mallorcaproperty.net</h5>
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="single-contact-info">
                  <p>
                    <i className="fa fa-map-marker" />
                    <Translate textKey="forms.address" />
                  </p>
                  <a href="https://goo.gl/maps/8g8ZvNxsjvGtY6V38" target="_blank" rel="noreferrer">
                    <h5>Carrer del Jardí Botànic 2</h5>
                    <h5>07012 Palma de Mallorca</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

function getPlaceholder(type, userLang) {
  let textKey = 'forms.message';
  if (type === realEstateSignUpForm) {
    textKey = 'forms.whyCollaborate';
  } else if (type === homeSellersForm) {
    textKey = 'forms.whySell';
  }
  return translate(textKey, userLang);
}

export default ContactForm;
