import React from 'react';

function AdImage({ imgUrl, url }) {
  return (
    <div className="ad-image call-to-action-area">
      <a href={url}>
        <div className="call-to-action">
          <img src={imgUrl} />
        </div>
      </a>
    </div>
  );
}

export default AdImage;
