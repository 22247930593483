import React from 'react';
import { realEstateSignUpForm } from '../../api/postContactForm';
import ContactForm from '../sectionComponents/ContactForm';

function RealEstateSignUpScreen() {
  return (
    <ContactForm
      titleKey="forms.realEstateSignUpFormTitle"
      imageUrl="/assets/img/hc/contact/realEstateSignUp.jpeg"
      type={realEstateSignUpForm}
    />
  );
}

export default RealEstateSignUpScreen;
