import React, { useState } from 'react';
import Translate from '../sharedComponents/Translate';

function FormSender({ firstValues, onSubmit, formContent, className }) {
  const [formValues, setFormValues] = useState(firstValues);
  const [status, setStatus] = useState();
  const [keyMessage, setKeyMessage] = useState();

  const setFormValue = (name) => (event) => {
    const isString = typeof event === 'string';
    const value = isString ? event : event.target.value;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const sendForm = async (event) => {
    event.preventDefault();
    if (status !== 'sending') {
      setStatus('sending');
      try {
        const result = await onSubmit(formValues);
        const { keyMessage } = result;
        setKeyMessage(keyMessage);
        setStatus('sent');
      } catch (e) {
        if (e.keyMessage) {
          setKeyMessage(e.keyMessage);
        } else {
          setKeyMessage('common.formSubmittedError');
        }
        setStatus('error');
      }
    }
  };

  return (
    <form onSubmit={sendForm} className={className}>
      {formContent({
        formValues,
        setFormValue,
        btnIcon: getIconByStatus(status),
        feedbackComponent: getFeedbackByStatus(status, keyMessage),
      })}
    </form>
  );
}

function getIconByStatus(status) {
  let icon = null;
  if (status == 'sending') {
    icon = (
      <div className="spinner" role="progressbar" aria-valuetext="Working…" aria-busy="true"></div>
    );
  } else if (status == 'sent') {
    icon = <i className="fa fa-check-circle" aria-hidden="true"></i>;
  } else if (status == 'error') {
    icon = <i className="fa fa-exclamation-circle" aria-hidden="true"></i>;
  }
  return icon;
}

function getFeedbackByStatus(status, keyMessage) {
  const textKey = keyMessage || 'common.formSubmitted';
  let message = null;
  if (status == 'sending') {
    message = <></>;
  } else if (status == 'sent') {
    message = (
      <p className="form-feedback">
        <Translate textKey={textKey} />
      </p>
    );
  } else if (status == 'error') {
    message = (
      <p className="form-feedback error">
        <Translate textKey={textKey} />
      </p>
    );
  }
  return message;
}

export default FormSender;
