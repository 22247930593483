import React from 'react';
import SubscribeAlerts from '../sectionComponents/SubscribeAlerts';

function CallToActionSubscribe({ title, description, buttonTitle }) {
  return (
    <div className="call-to-action-subscribe">
      <h3>{title}</h3>
      <p className="description">{description}</p>
      <SubscribeAlerts textButton={buttonTitle} />
    </div>
  );
}

export default CallToActionSubscribe;
