import React, { useCallback, useEffect, useContext, useState } from 'react';
import Footer from '../globalComponents/Footer';
import MainHeader from '../globalComponents/MainHeader';
import { AppContext } from '../../App.context';
import useBuilder from '../../hooks/useBuilder';
import BuilderPage from '../sharedComponents/BuilderPage';

function HomeScreen() {
  const { appState, appActions } = useContext(AppContext);
  const { buildPropsComponents } = useBuilder();
  const [loaded, setLoaded] = useState(false);
  const [homeComponents, setHomeComponents] = useState();
  const fetchBuilder = useCallback(async (lang) => {
    const resp = await appActions.getBuilder(lang);
    const allComponents = buildPropsComponents(resp);
    setHomeComponents(allComponents);
    setLoaded(true);
  }, []);

  useEffect(() => {
    fetchBuilder(appState.userLang);
  }, [appState.userLang]);

  return (
    <div className="home">
      {loaded && (
        <>
          <MainHeader scrollDownPosition={350} />
          <BuilderPage components={homeComponents} />
          <Footer />
        </>
      )}
    </div>
  );
}

export default HomeScreen;
