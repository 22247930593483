import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../App.context';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import useFilters from '../../hooks/useFilters';

function PaginationPages({ page, totalPages, pageType }) {
  const [selectedPage, setSelectedPage] = useState(page);
  const [options, setOptions] = useState([]);
  const { appState } = useContext(AppContext);
  const { getFiltersFromUrl } = useFilters();
  const filtersUrl = getFiltersFromUrl(appState.currentUrl);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const arrayValues = Array.from(Array(totalPages).keys());
    const optionsSelect = arrayValues.map((value) => {
      return { value: value + 1, text: value + 1 };
    });
    setOptions(optionsSelect);
  }, []);

  useEffect(() => {
    if (selectedPage !== page) {
      setSelectedPage(page);
    }
  }, [page]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 120;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 120;
    }
  };

  return (
    <div className="pagination-pages">
      <div className="pagination-control-pages">
        <div className="arrow-control">
          <FontAwesomeIcon
            icon={faCaretLeft}
            size="lg"
            onClick={() => {
              scrollLeft();
            }}
          />
        </div>
        <div className="pages" ref={scrollContainerRef}>
          {options.map((option, index) => (
            <Link
              to={`/${pageType}?filter=${encodeURIComponent(
                JSON.stringify({ ...filtersUrl, ...{ page: option.value } }),
              )}`}
              className={`link-number ${option.value == (filtersUrl.page || 1) ? 'selected' : ''}`}
              key={index}
            >
              {option.text}
            </Link>
          ))}
        </div>
        <div className="arrow-control">
          <FontAwesomeIcon
            icon={faCaretRight}
            size="lg"
            onClick={() => {
              scrollRight();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PaginationPages;
