import React from 'react';
import Translate from '../sharedComponents/Translate';

function CallToAction({ titleKey, children }) {
  return (
    <div className="call-to-action-area">
      <div className="container">
        <div className="call-to-action style-two">
          <div className="cta-content">
            <h3 className="title">
              <Translate textKey={titleKey} />
            </h3>
            <div className="d-flex justify-content-center mt-3">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
