import axios from 'axios';
import constants from '../constants';

const builderMapId = {
  es: '1529',
  en: '108',
  de: '1531',
};

async function getBuilder(lang) {
  const id = builderMapId[lang] || builderMapId.en;
  const { data } = await axios.get(`${constants.apiWordPress}/${id}`);
  return data;
}

export default getBuilder;
