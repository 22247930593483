import React, { useContext } from 'react';
import { AppContext } from '../../App.context';
import Translate from '../sharedComponents/Translate';
import { translate } from '../../data/languages/languageUtils';
import postContactForm, { propertyEnquiryForm } from '../../api/postContactForm';
import FormSender from './FormSender';
import NameFields from '../sharedComponents/NameFields';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function PropertyDetailsForm({ propertyReference, propertyName, agency }) {
  const context = useContext(AppContext);
  const { userLang } = context.appState;

  const validForm = (formValues) => {
    const { email, firstName, lastName, phone, message } = formValues;
    return (
      email.length === 0 ||
      firstName.length === 0 ||
      lastName.length === 0 ||
      phone.length < 5 ||
      message.length === 0
    );
  };

  return (
    <div className="widget widget-owner-info mt-lg-0 mt-5">
      <div className="owner-info text-center">
        <div className="thumb">
          <img src={'/assets/img/hc/specialist.jpg'} alt="Lucy Adamson" />
        </div>
        <div className="details">
          <h6>
            <Translate textKey="propertyDetails.contactName" />
          </h6>
          <span className="designation">
            <Translate textKey="propertyDetails.propertySpecialist" />
          </span>
        </div>
      </div>
      <div className="contact">
        <h6>
          <Translate textKey="propertyDetails.contactUs" />
        </h6>
        <FormSender
          onSubmit={(formData) => postContactForm(formData, propertyEnquiryForm)}
          firstValues={{
            propertyReference,
            propertyName,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            propertyURL: window.location.href,
            agency,
          }}
          formContent={({ formValues, setFormValue, btnIcon, feedbackComponent }) => {
            return (
              <>
                <NameFields
                  formValues={formValues}
                  setFormValue={setFormValue}
                  userLang={userLang}
                />
                <div className="rld-single-input">
                  <input
                    type="email"
                    placeholder={translate('propertyDetails.email', userLang)}
                    value={formValues.email}
                    onChange={setFormValue('email')}
                    required
                  />
                </div>
                <div className="rld-single-input">
                  <PhoneInput
                    country="es"
                    inputProps={{
                      name: 'phone',
                      required: true,
                    }}
                    containerClass="phone-country"
                    countryCodeEditable={false}
                    value={formValues.phone}
                    onChange={setFormValue('phone')}
                  />
                </div>
                <div className="rld-single-input">
                  <input
                    type="text"
                    placeholder={translate('propertyDetails.messages', userLang)}
                    value={formValues.message}
                    onChange={setFormValue('message')}
                  />
                </div>
                <div className="button-with-feedback">
                  <button className="btn btn-yellow" disabled={validForm(formValues)}>
                    <Translate textKey="propertyDetails.sendMessages" />
                    {btnIcon}
                  </button>
                  {feedbackComponent}
                </div>
                {validForm(formValues) && (
                  <p className="text-required">
                    <Translate textKey="forms.requireAllFields" />
                  </p>
                )}
              </>
            );
          }}
        />
      </div>
      <div className="contact-info">
        <h6 className="mb-3">
          <Translate textKey="propertyDetails.contactInfo" />
        </h6>
        <div className="media">
          <div className="media-left">
            <i className="fa fa-map-marker" />
          </div>
          <div className="media-body">
            <p>
              <Translate textKey="propertyDetails.address" />
            </p>
            <a href="https://goo.gl/maps/8g8ZvNxsjvGtY6V38" target="_blank" rel="noreferrer">
              <Translate textKey="propertyDetails.addressData" />
            </a>
          </div>
        </div>
        <div className="media">
          <div className="media-left">
            <i className="fa fa-phone" />
          </div>
          <div className="media-body">
            <p>
              <Translate textKey="propertyDetails.phone" />
            </p>
            <a href={`tel:${translate('propertyDetails.phoneData', userLang)}`}>
              <Translate textKey="propertyDetails.phoneData" />
            </a>
          </div>
        </div>
        <div className="media mb-0">
          <div className="media-left">
            <i className="fa fa-envelope" />
          </div>
          <div className="media-body">
            <p>
              <Translate textKey="propertyDetails.email" />
            </p>
            <a href={`mailto:${translate('propertyDetails.emailData', userLang)}`}>
              <Translate textKey="propertyDetails.emailData" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailsForm;
