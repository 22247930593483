import React from 'react';
import Translate from '../sharedComponents/Translate';
import PropertyCard from '../sharedComponents/PropertyCard';

export default function SearchResults({ properties, type, isLoading, pageType }) {
  let containerClasses = type === 'grid' ? 'row justify-content-center' : '';
  containerClasses += pageType == 'xmlFeed' ? ' xml-feed' : '';
  const itemClasses = type === 'grid' ? 'col-xl-4 col-lg-4 col-md-6 col-sm-6' : 'col-lg-12';

  return (
    <div className="properties-grid">
      {isLoading ? (
        <div
          className="spinner"
          role="progressbar"
          aria-valuetext="Working…"
          aria-busy="true"
        ></div>
      ) : properties.length > 0 ? (
        <div className={`row ${containerClasses}`}>
          {properties.map((item, i) => (
            <div className={`${itemClasses} pointer`} key={i}>
              <PropertyCard
                item={item}
                showXmlButton={pageType === 'xmlFeed'}
                rowMode={type !== 'grid'}
              />
            </div>
          ))}
        </div>
      ) : (
        <h4>
          <Translate textKey="properties.noResults" />
        </h4>
      )}
    </div>
  );
}
