const constants = {
  apiUrl: 'https://abcmallorcapropertiesnode.azurewebsites.net',
  apiUrlV1: 'https://abcmallorcapropertiesnode.azurewebsites.net/api/v1',
  apiUrlV2: 'https://abcmallorcapropertiesnode.azurewebsites.net/api/v2',
  apiWordPress: 'https://abcmallorcapropertiesnode.azurewebsites.net/api/v1/wp-json/acf/v3/pages',
  wpUrl: 'https://wp.mallorcaproperty.net/wp-json',
  wpAuth:
    'Basic Y2tfOWEyZGJlM2EwZTE0N2M0MTdlMzMxNDYyN2E2ZjdkMWIwZTkzYjJlNzpjc19hMzlhNzM1NzQ5ZTI3N2Q1NTI3OGI2NjhjY2UwM2Q4Nzg5ODU0YWZi',
};

export default constants;
