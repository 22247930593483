import React, { useContext, useState } from 'react';
import uploadXML from './api/uploadXML';
import { AppContext } from './App.context';

export const XmlFeedContext = React.createContext({});

export default function XmlFeedContextProvider({ children }) {
  const { appState } = useContext(AppContext);
  const [state, setState] = useState({
    properties: [],
    generating: false,
  });

  const actions = {
    addProperty: (property) => {
      updateState({ properties: [...state.properties, property] });
    },
    removeProperty: (propertyId) => {
      updateState({
        properties: state.properties.filter((property) => property.id != propertyId),
      });
    },
    generateXML: async () => {
      try {
        updateState({ generating: true });
        await uploadXML(state.properties, appState.userLang);
        updateState({ generating: false });
      } catch (e) {
        console.error(e);
        updateState({ generating: false });
        throw e;
      }
    },
  };

  function updateState(newState) {
    setState((previousState) => ({ ...previousState, ...newState }));
  }

  return <XmlFeedContext.Provider value={{ state, actions }}>{children}</XmlFeedContext.Provider>;
}
