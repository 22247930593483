import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Translate from '../sharedComponents/Translate';
import { AppContext } from '../../App.context';
import SubscribeAlerts from '../sectionComponents/SubscribeAlerts';
import useHeader from '../../hooks/useHeader';
import HeaderResponsive from './HeaderResponsive';

function HeaderHome({ pageType }) {
  const { links, createLink } = useHeader(pageType);
  const { appState, appActions } = useContext(AppContext);
  const {
    filters: { propertyTypes },
  } = appState;

  const GroupLinks = ({ links }) =>
    links.map((item, i) => (
      <Link key={i} className="item" to={createLink(propertyTypes, item.typeId)}>
        <Translate textKey={item.titleKey} />
      </Link>
    ));

  return (
    <header className="header-home">
      <HeaderResponsive pageType={pageType} />
      <nav className="nav-app">
        <div className="languages">
          <a
            data-testid="en-language-selector"
            className={appState.userLang === 'en' ? 'selected' : ''}
            onClick={() => appActions.changeUserLang('en')}
          >
            EN
          </a>
          <a
            data-testid="es-language-selector"
            className={appState.userLang === 'es' ? 'selected' : ''}
            onClick={() => appActions.changeUserLang('es')}
          >
            ES
          </a>
          <a
            data-testid="de-language-selector"
            className={appState.userLang === 'de' ? 'selected' : ''}
            onClick={() => appActions.changeUserLang('de')}
          >
            DE
          </a>
        </div>
        <div className="main-menu">
          <GroupLinks links={links} />
          <a className="item" href="https://www.helencummins.com/">
            Lifestyle
          </a>
        </div>
        <SubscribeAlerts buttonTextKey="forms.createAlert" ghostStyle={true} />
      </nav>
    </header>
  );
}

export default HeaderHome;
