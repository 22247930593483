import React from 'react';
import { homeSellersForm } from '../../api/postContactForm';
import ContactForm from '../sectionComponents/ContactForm';

function HomeSellersScreen() {
  return (
    <ContactForm
      titleKey="forms.homeSellersFormTitle"
      imageUrl="/assets/img/hc/contact/homeSellers.jpeg"
      type={homeSellersForm}
    />
  );
}

export default HomeSellersScreen;
