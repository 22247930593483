import React, { useContext } from 'react';
import Translate from '../sharedComponents/Translate';
import { Link } from 'react-router-dom';
import { AppContext } from '../../App.context';
import { ReactComponent as Logo } from '../../svg/logo.svg';
import useHeader from '../../hooks/useHeader';

function HeaderResponsive({ pageType }) {
  const { links, createLink } = useHeader(pageType);
  const { appState, appActions } = useContext(AppContext);
  const {
    filters: { propertyTypes },
  } = appState;

  const GroupLinks = ({ links }) =>
    links.map((item, i) => (
      <Link key={i} className="item" to={createLink(propertyTypes, item.typeId)}>
        <Translate textKey={item.titleKey} />
      </Link>
    ));

  return (
    <div className="header-mobile">
      <nav className="navbar navbar-area navbar-expand-lg">
        <div className="container nav-container">
          <div className="small-language-selector">
            <div className="d-flex d-lg-none">
              <select
                value={appState.userLang}
                onChange={(event) => {
                  appActions.changeUserLang(event.target.value);
                }}
              >
                <option value="en">EN</option>
                <option value="es">ES</option>
                <option value="de">DE</option>
              </select>
            </div>
          </div>
          <div className="logo readeal-top">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="responsive-mobile-menu">
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-toggle="collapse"
              data-target="#realdeal_menu_filters"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="realdeal_menu_filters">
            <div className="d-lg-none">
              <div className="vertical-links">
                <GroupLinks links={links} />
                <a className="item no-border-bottom" href="https://www.helencummins.com/">
                  Lifestyle
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default HeaderResponsive;
