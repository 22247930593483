import { defaultFilter } from './../components/sharedComponents/AdvancedSearchFilter';

const useHeader = (pageType) => {
  const links = [
    {
      titleKey: 'home.villas',
      typeId: 2,
    },
    {
      titleKey: 'home.fincas',
      typeId: 4,
    },
    {
      titleKey: 'home.apartments',
      typeId: 3,
    },
    {
      titleKey: 'home.townhouses',
      typeId: 1,
    },
  ];

  const createLink = (propertyTypes, typeId) => {
    const propertyType = propertyTypes?.find((type) => type.id === typeId);
    const filter = { ...defaultFilter, propertyTypes: propertyType ? [propertyType.id] : null };
    return `/${pageType}?filter=${encodeURIComponent(JSON.stringify(filter))}`;
  };

  return {
    links,
    createLink,
  };
};

export default useHeader;
