import React from 'react';
import Translate from '../sharedComponents/Translate';

function PageHeader({ titleKey, imageUrl }) {
  const inlineStyle = {
    backgroundImage: `url(${imageUrl})`,
  };
  return (
    <div className="breadcrumb-area jarallax" style={inlineStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <h1 className="page-title">
                <Translate textKey={titleKey} />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
