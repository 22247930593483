import React from 'react';
import { Link } from 'react-router-dom';

function WhatDoYouWant({ cards }) {
  return (
    <div className="what-do-you-want-container">
      <div className="cards">
        {cards.map((card, index) => (
          <div className="card" key={index}>
            <div className="icon">
              <img src={card.iconUrl} />
            </div>
            <div className="card-title">
              <h3 className="title">{card.title}</h3>
            </div>
            <div className="card-content">
              <p className="description">{card.description}</p>
              <Link to={card.linkButton} className="btn btn-primary">
                {card.buttonName}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatDoYouWant;
