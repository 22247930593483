import axios from 'axios';
import constants from '../constants';
export const maxResults = 24;

async function analyzeProperties() {
  const response = await axios.get(`${constants.apiUrlV2}/properties/analyze`);
  return response.data;
}

export default analyzeProperties;
