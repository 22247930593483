import axios from 'axios';
import constants from '../constants';
import { buildRequirements } from './utils';
export const maxResults = 24;

async function getProperties(lang, filter = {}) {
  const requirements = buildRequirements(lang, filter);
  const response = await axios.get(
    `${constants.apiUrlV2}/properties/suggest?requirements=${encodeURIComponent(
      JSON.stringify(requirements),
    )}`,
  );
  return response.data;
}

export default getProperties;
