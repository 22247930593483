import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ButtonToggle from './ButtonToggle';

export default function IconDropdown({ placeholder, icon, options = [], onChange }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={ButtonToggle} iconRight={icon}>
        {placeholder}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.id} onClick={() => onChange(option)}>
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
