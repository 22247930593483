import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { maxResults } from '../../api/getProperties';
import { AppContext } from '../../App.context';
import IconDropdown from '../sharedComponents/IconDropdown';
import Translate from '../sharedComponents/Translate';
import SearchResults from './SearchResults';
import { ReactComponent as GridIcon } from '../../svg/gridView.svg';
import { ReactComponent as ListIcon } from '../../svg/listView.svg';
import { defaultFilter } from '../sharedComponents/AdvancedSearchFilter';
import SubscribeAlerts from '../sectionComponents/SubscribeAlerts';
import Pagination from '../sharedComponents/Pagination';
import { Link } from 'react-router-dom';

function PropertiesList({ pageType }) {
  const [viewSelected, setViewSelected] = useState('grid');
  const [page, setPage] = useState(1);
  const lastFilter = useRef({});
  const { appState, appActions } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filter = queryParams.get('filter')
      ? JSON.parse(queryParams.get('filter'))
      : defaultFilter;
    lastFilter.current = filter;
    const initPage = filter.page ? filter.page : 1;
    setPage(initPage);
    appActions.searchProperties(filter);
  }, [location, appState.userLang]);

  let selectedOrder = null;
  if (lastFilter.current) {
    const { order, orderDirection } = lastFilter.current;
    selectedOrder = sortOptions.find(
      (orderOption) => orderOption.order === order && orderOption.orderDirection === orderDirection,
    );
    if (!selectedOrder) {
      selectedOrder = sortOptions[1];
    }
  }

  return (
    <div className="search-page-wrap pd-bottom-70">
      <div className="search-container">
        <div className="container">
          {!appState.propertiesLoading && (
            <div className="row pt-3 mb-3">
              <div className="col-md-12">
                <div className="actions-container">
                  <SubscribeAlerts buttonTextKey="forms.createAlert"></SubscribeAlerts>
                  <Pagination
                    page={page}
                    maxResults={maxResults}
                    totalResults={appState.totalSearchProperties}
                    pageType={pageType}
                  />
                  <div className="actions">
                    <div className="select-filter-properties">
                      <IconDropdown
                        placeholder={selectedOrder && <Translate textKey={selectedOrder.nameKey} />}
                        icon={faCaretDown}
                        options={sortOptions.map((option) => ({
                          ...option,
                          name: <Translate textKey={option.nameKey} />,
                        }))}
                        onChange={(option) => {
                          const filter = {
                            ...lastFilter.current,
                            order: option.order,
                            orderDirection: option.orderDirection,
                          };
                          history.push(
                            `/${pageType}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
                          );
                        }}
                      />
                    </div>
                    <div
                      className={viewSelected === 'grid' ? 'icon-button disabled' : 'icon-button'}
                      data-testid="grid-selector"
                      onClick={() => setViewSelected('grid')}
                    >
                      <GridIcon />
                    </div>
                    <div
                      className={viewSelected === 'list' ? 'icon-button disabled' : 'icon-button'}
                      data-testid="list-selector"
                      onClick={() => setViewSelected('list')}
                    >
                      <ListIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <SearchResults
            isLoading={appState.propertiesLoading}
            filters={appState.filters}
            properties={appState.properties}
            type={viewSelected}
            pageType={pageType}
          />
          {!appState.propertiesLoading && appState.properties.length > 0 && (
            <div className="row pt-3 mb-3">
              <div className="col-md-12">
                <div className="actions-container">
                  <SubscribeAlerts buttonTextKey="forms.createAlert"></SubscribeAlerts>
                  <Pagination
                    page={page}
                    pageType={pageType}
                    maxResults={maxResults}
                    totalResults={appState.totalSearchProperties}
                  />
                  <div className="buttons-actions">
                    <Link
                      to={`/${pageType}?filter=${encodeURIComponent(
                        JSON.stringify({ ...lastFilter.current, ...{ page: page - 1 } }),
                      )}`}
                      className={`btn btn-yellow ${page - 1 === 0 ? 'disabled' : ''}`}
                    >
                      <Translate textKey={'properties.prev'} />
                    </Link>
                    <Link
                      to={`/${pageType}?filter=${encodeURIComponent(
                        JSON.stringify({ ...lastFilter.current, ...{ page: page + 1 } }),
                      )}`}
                      className={`btn btn-yellow ${
                        page + 1 > Math.ceil(appState.totalSearchProperties / maxResults)
                          ? 'disabled'
                          : ''
                      }`}
                    >
                      <Translate textKey={'properties.next'} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const sortOptions = [
  {
    id: 'date,asc',
    order: 'date',
    orderDirection: 'asc',
    nameKey: 'properties.sort.dateAsc',
  },
  {
    id: 'date,desc',
    order: 'date',
    orderDirection: 'desc',
    nameKey: 'properties.sort.dateDesc',
  },
  {
    id: 'price,asc',
    order: 'price',
    orderDirection: 'asc',
    nameKey: 'properties.sort.priceAsc',
  },
  {
    id: 'price,desc',
    order: 'price',
    orderDirection: 'desc',
    nameKey: 'properties.sort.priceDesc',
  },
  {
    id: 'name,asc',
    order: 'name',
    orderDirection: 'asc',
    nameKey: 'properties.sort.nameAsc',
  },
  {
    id: 'name,desc',
    orderDirection: 'desc',
    nameKey: 'properties.sort.nameDesc',
  },
];

export default PropertiesList;
