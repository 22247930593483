import postContactForm, { receiveRecommendationsForm } from './postContactForm';
import getEmailList from './getEmailList';

async function postSubscribeNewProperties(formData) {
  let subscriber = null;
  try {
    subscriber = await getSubscriber(formData.email);
  } catch (error) {
    return { keyMessage: 'errors.server' };
  }

  if (subscriber) {
    return Promise.resolve({
      keyMessage: 'subscribe.alreadySubscribe',
      subscribe: subscriber,
    });
  }

  try {
    await postContactForm(formData, receiveRecommendationsForm);
    return { keyMessage: 'subscribe.success' };
  } catch (error) {
    return { keyMessage: 'errors.server' };
  }
}

async function getSubscriber(userMail) {
  try {
    const { entries } = await getEmailList();
    return entries.find((entry) => entry['2'] === userMail);
  } catch (error) {
    return error;
  }
}

export default postSubscribeNewProperties;
