import React, { useState } from 'react';
import HeaderFilters from '../globalComponents/HeaderFilters';
import AuthorizationForm from '../sharedComponents/AuthorizationForm';
import { Button } from 'react-bootstrap';
import analyzeProperties from './../../api/analyzeProperties';
import { Link } from 'react-router-dom';
import Translate from '../sharedComponents/Translate';

function PropertiesAnalyze() {
  const itemsPerPage = 10;
  const [allGroups, setAllGroups] = useState([]);
  const [visibleGroups, setVisibleGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [isValidAuth, setIsValidAuth] = useState(false);

  async function startAnalyze() {
    setIsSearching(true);
    const response = await analyzeProperties();
    setAllGroups(response);
    setVisibleGroups(response.slice(0, itemsPerPage));
    setIsSearching(false);
  }

  const handleClickShowMore = () => {
    const endIndex = (currentPage + 1) * itemsPerPage;
    setVisibleGroups(allGroups.slice(0, endIndex));
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="page-wrapper properties-analyze-screen">
      <HeaderFilters sticky={true} pageType={'properties'} />
      <div className="page-content">
        <div className="container">
          {isValidAuth ? (
            <div className="results-container">
              <div className="actions">
                <h5>
                  <Translate textKey="analyze.similarProperties" />: {allGroups.length}
                </h5>
                <Button
                  disabled={isSearching}
                  onClick={() => {
                    startAnalyze();
                  }}
                >
                  <Translate textKey="analyze.checkProperties" />
                </Button>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>N</th>
                    <th>
                      <Translate textKey="analyze.property" />
                    </th>
                    <th>
                      <Translate textKey="analyze.price" />
                    </th>
                    <th>
                      <Translate textKey="analyze.constructedAreaSize" />
                    </th>
                    <th>
                      <Translate textKey="analyze.areaSize" />
                    </th>
                    <th>
                      <Translate textKey="analyze.similarProperties" />
                    </th>
                  </tr>
                </thead>
                <thead>
                  {!isSearching &&
                    visibleGroups.map((group, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{group[0].name}</td>
                        <td>{group[0].price}</td>
                        <td>
                          {group[0].constructedAreaSizeM2 ? group[0].constructedAreaSizeM2 : 0}
                        </td>
                        <td>{group[0].areaSizeM2 ? group[0].areaSizeM2 : 0}</td>
                        <td>
                          <div className="links">
                            {group.map((item, key) => (
                              <div key={key}>
                                <Link to={`/property/${item.id}`} target="_blank">
                                  <span className="title readeal-top">{item.id}</span>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                </thead>
              </table>
              {isSearching && (
                <div
                  className="spinner"
                  role="progressbar"
                  aria-valuetext="Working…"
                  aria-busy="true"
                ></div>
              )}

              <div className="groups">
                {!isSearching && visibleGroups.length < allGroups.length && (
                  <Button onClick={handleClickShowMore}>
                    <Translate textKey="analyze.loadMore" />
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="authorization-container">
              <AuthorizationForm
                onSubmit={(response) => {
                  setIsValidAuth(response.isValidCode);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertiesAnalyze;
