import React from 'react';
import Footer from '../globalComponents/Footer';
import PropertiesList from '../sectionComponents/PropertiesList';
import CallToActionContact from '../sectionComponents/CallToActionContact';
import XmlFeedStatus from '../sectionComponents/XmlFeedStatus';
import HeaderFilters from '../globalComponents/HeaderFilters';

function PropertiesListScreen({ pageType = 'properties' }) {
  return (
    <div className="page-wrapper">
      <HeaderFilters sticky={true} pageType={pageType} />
      <div className="page-content bg-gray">
        <PropertiesList pageType={pageType} />
        {pageType == 'properties' ? (
          <>
            <CallToActionContact />
            <Footer white />
          </>
        ) : (
          <XmlFeedStatus />
        )}
      </div>
    </div>
  );
}

export default PropertiesListScreen;
