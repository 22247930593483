import React, { useContext, useEffect } from 'react';
import { ReactComponent as Logo } from '../../svg/logo.svg';
import Translate from '../sharedComponents/Translate';
import FormSender from '../sectionComponents/FormSender';
import postContactForm, { contactForm, newsletterForm } from '../../api/postContactForm';
import { translate } from '../../data/languages/languageUtils';
import { AppContext } from '../../App.context';
import NameFields from '../sharedComponents/NameFields';

function Footer({ white }) {
  const {
    appState: { userLang, filters, previousUrl, currentUrl },
  } = useContext(AppContext);

  useEffect(() => {
    const minscript = document.createElement('script');
    minscript.async = true;
    minscript.src = '/assets/js/main.js';

    document.body.appendChild(minscript);
  }, []);

  return (
    <footer className="footer-area style-two">
      <div className="container">
        <div className={`subscribe-area ${white && 'bg-white'}`}>
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 text-center">
              <h2>
                <Translate textKey="footer.getUpdate" />
              </h2>
              <FormSender
                onSubmit={(formData) => postContactForm(formData, newsletterForm)}
                firstValues={{ email: '' }}
                formContent={({ formValues, setFormValue, btnIcon, feedbackComponent }) => {
                  return (
                    <div className="rld-single-input">
                      <input
                        type="email"
                        placeholder={translate('footer.yourEmail', userLang).toLowerCase()}
                        value={formValues.email}
                        onChange={setFormValue('email')}
                        required
                        data-testid="subscribe-email"
                      />
                      <div className="button-with-feedback">
                        <button className="btn btn-primary">
                          <Translate textKey="footer.subscribe" />
                          {btnIcon}
                        </button>
                        {feedbackComponent}
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="row">
            <div className="col-sm-4">
              <a className="footer-logo" href="/">
                <Logo />
              </a>
            </div>
            <div className="col-sm-8">
              <div className="footer-social text-sm-right">
                <span>
                  <Translate textKey="footer.followUs" uppercase />
                  <i className="la la-arrow-right" />
                </span>
                <ul className="social-icon">
                  {socialIcons.map((item, i) => (
                    <li key={i}>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <i className={item.icon} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">
                  <Translate textKey={`footer.links.searchByRegion`} />
                </h4>
                <ul>
                  {regionLinks.map((item, i) => {
                    const region = filters.propertyRegions?.find(
                      (propertyRegion) => propertyRegion.id == item.regionId,
                    );
                    const url = region ? getRegionLink(region) : null;
                    const text = region?.name;
                    return (
                      <li key={i}>
                        <a href={url}>{text}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {Object.keys(footerLinks).map((linksKey) => (
              <div className="col-lg-3 col-sm-6" key={linksKey}>
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">
                    <Translate textKey={`footer.links.${linksKey}`} />
                  </h4>
                  <ul>
                    {footerLinks[linksKey].map((item, i) => (
                      <li key={i}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <Translate textKey={`footer.links.${item.titleKey}`} />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
            <div className="col-lg-3 col-sm-6" id="footer-form-container">
              <FormSender
                onSubmit={(formValues) =>
                  postContactForm({ ...formValues, previousUrl, currentUrl }, contactForm)
                }
                firstValues={{ firstName: '', lastName: '', email: '', message: '' }}
                formContent={({ formValues, setFormValue, btnIcon, feedbackComponent }) => {
                  return (
                    <div className="widget widget-subscribe">
                      <NameFields
                        formValues={formValues}
                        setFormValue={setFormValue}
                        userLang={userLang}
                      />
                      <div className="rld-single-input">
                        <input
                          type="email"
                          placeholder={translate('footer.yourEmail', userLang)}
                          value={formValues.email}
                          onChange={setFormValue('email')}
                          required
                        />
                      </div>
                      <div className="button-with-feedback">
                        <button className="btn btn-yellow">
                          <Translate textKey="footer.contactUs" />
                          {btnIcon}
                        </button>
                        {feedbackComponent}
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const socialIcons = [
  {
    icon: 'fa fa-instagram',
    url: 'https://www.instagram.com/helen_cummins/',
  },
  {
    icon: 'fa fa-linkedin',
    url: 'https://www.linkedin.com/in/dr-helen-cummins-23301050/',
  },
  {
    icon: 'fa fa-facebook',
    url: 'https://www.facebook.com/LivingbyHelenCummins/',
  },
];

const regionLinks = [
  {
    regionId: 1,
  },
  {
    regionId: 2,
  },
  {
    regionId: 3,
  },
  {
    regionId: 4,
  },
  {
    regionId: 5,
  },
  {
    regionId: 6,
  },
];

const footerLinks = {
  hcLinks: [
    {
      titleKey: 'hcLinks1',
      url: 'https://www.hcmallorcaliving.com/#how-it-works',
    },
    {
      titleKey: 'hcLinks2',
      url: 'https://www.hcmallorcaliving.com/#client-stories',
    },
    {
      titleKey: 'hcLinks3',
      url: 'https://www.hcmallorcaliving.com/#why-hc',
    },
    {
      titleKey: 'hcLinks4',
      url: 'https://www.hcmallorcaliving.com/book-session/',
    },
    {
      titleKey: 'hcLinks5',
      url: 'https://www.hcmallorcaliving.com/about-us/',
    },
    {
      titleKey: 'hcLinks6',
      url: 'https://www.hcmallorcaliving.com/contact-us/',
    },
  ],
  quickLinks: [
    {
      titleKey: 'quickLinksLink1',
      url: 'https://www.hcmallorcaliving.com',
    },
    {
      titleKey: 'quickLinksLink2',
      url: 'https://www.helencummins.com',
    },
    {
      titleKey: 'quickLinksLink3',
      url: 'https://www.abcmallorcadigitalmedia.com',
    },
    {
      titleKey: 'quickLinksLink4',
      url: 'https://www.hcbusinessacademy.com',
    },
    {
      titleKey: 'quickLinksLink5',
      url: 'https://www.abc-mallorca.com',
    },
  ],
};

function getRegionLink(region) {
  return `/properties?filter=${encodeURIComponent(
    JSON.stringify({
      reference: '',
      propertyFeatures: [],
      order: 'date',
      orderDirection: 'desc',
      propertyRegion: region?.id,
    }),
  )}`;
}

export default Footer;
