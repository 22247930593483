import React from 'react';
import Translate from '../sharedComponents/Translate';
import CallToAction from '../sectionComponents/CallToAction';
import { Link } from 'react-router-dom';

function CallToActionContact() {
  return (
    <CallToAction titleKey="contactAgent.contactWithOurAgent">
      <Link to="/forms/contactUs" className="btn btn-primary mb-2 mb-sm-0 mr-2">
        <Translate textKey="contactAgent.getAppointment" />
      </Link>
      <a className="btn btn-primary mb-2 mb-sm-0 mr-lg-3 mr-0" href="tel:+34971708888">
        <Translate textKey="contactAgent.makeACall" />
      </a>
    </CallToAction>
  );
}

export default CallToActionContact;
