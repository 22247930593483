import React, { useContext } from 'react';
import { AppContext } from '../../App.context';
import Translate from '../sharedComponents/Translate';
import { translate } from '../../data/languages/languageUtils';
import postContactForm, { propertyEnquiryForm } from '../../api/postContactForm';
import FormSender from './FormSender';
import NameFields from '../sharedComponents/NameFields';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function PropertyDetailsBottomForm({ propertyReference, propertyName, agency }) {
  const context = useContext(AppContext);
  const { userLang } = context.appState;

  const validForm = (formValues) => {
    const { email, firstName, lastName, phone, message } = formValues;
    return (
      email.length === 0 ||
      firstName.length === 0 ||
      lastName.length === 0 ||
      phone.length < 5 ||
      message.length === 0
    );
  };

  return (
    <FormSender
      className="contact-form-wrap contact-form-bg"
      onSubmit={(formData) => postContactForm(formData, propertyEnquiryForm)}
      firstValues={{
        propertyReference,
        propertyName,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        propertyURL: window.location.href,
        agency,
        contactMethod: 'Phone',
        preferredTime: 'Morning',
      }}
      formContent={({ formValues, setFormValue, btnIcon, feedbackComponent }) => {
        return (
          <>
            <h4>
              <Translate textKey="propertyDetails.contactUs" />
            </h4>
            <div className="fields-row">
              <NameFields formValues={formValues} setFormValue={setFormValue} userLang={userLang} />
            </div>
            <div className="fields-row">
              <div className="rld-single-input">
                <input
                  type="email"
                  placeholder={translate('propertyDetails.email', userLang)}
                  value={formValues.email}
                  onChange={setFormValue('email')}
                  required
                />
              </div>
              <div className="rld-single-input">
                <PhoneInput
                  country="es"
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  inputClass="phone-field"
                  countryCodeEditable={false}
                  value={formValues.phone}
                  onChange={setFormValue('phone')}
                />
              </div>
            </div>
            <div className="fields-row">
              <div className="rld-radio-input">
                <span>{translate('propertyDetails.preferredHours', userLang)}</span>
                <label>
                  <input
                    type="radio"
                    value="Morning"
                    checked={formValues.preferredTime == 'Morning'}
                    onChange={setFormValue('preferredTime')}
                  />
                  {translate('propertyDetails.morning', userLang)}
                </label>
                <label>
                  <input
                    type="radio"
                    value="Afternoon"
                    checked={formValues.preferredTime == 'Afternoon'}
                    onChange={setFormValue('preferredTime')}
                  />
                  {translate('propertyDetails.afternoon', userLang)}
                </label>
                <label>
                  <input
                    type="radio"
                    value="Evening"
                    checked={formValues.preferredTime == 'Evening'}
                    onChange={setFormValue('preferredTime')}
                  />
                  {translate('propertyDetails.evening', userLang)}
                </label>
              </div>
            </div>
            <div className="rld-single-input">
              <textarea
                rows={2}
                placeholder={translate('propertyDetails.messages', userLang)}
                value={formValues.message}
                onChange={setFormValue('message')}
              />
            </div>
            <div className="button-with-feedback">
              <button className="btn btn-yellow" disabled={validForm(formValues)}>
                <Translate textKey="propertyDetails.sendMessages" />
                {btnIcon}
              </button>
              {feedbackComponent}
            </div>
            {validForm(formValues) && (
              <p className="text-required" style={{ fontSize: 14, marginTop: 5 }}>
                <Translate textKey="forms.requireAllFields" />
              </p>
            )}
          </>
        );
      }}
    />
  );
}

export default PropertyDetailsBottomForm;
