import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AppContext } from '../../App.context';
import PropertyCard from '../sharedComponents/PropertyCard';
import { Link } from 'react-router-dom';
import Translate from '../sharedComponents/Translate';

const LatestProperties = ({ title, typeId }) => {
  const { appState, appActions } = useContext(AppContext);
  const [properties, setProperties] = useState([]);
  const typeIds = typeId && typeId !== '0' ? [typeId] : '';
  const filters = {
    order: 'date',
    orderDirection: 'desc',
    count: 3,
    propertyTypes: typeIds,
  };

  const fetchProperties = useCallback(async (lang) => {
    const { properties } = await appActions.getProperties(lang, filters);
    setProperties(properties);
  }, []);

  useEffect(() => {
    fetchProperties(appState.userLang);
  }, [appState.userLang]);

  return (
    <div className="latest-properties">
      <div className="title-header">
        <h3 className="title">{title}</h3>
        <Link
          className="search-link"
          to={`/properties?filter=${encodeURIComponent(
            JSON.stringify({ ...filters, ...{ count: 24 } }),
          )}`}
        >
          <Translate textKey="home.viewAll" />
        </Link>
      </div>
      <div className="properties-grid">
        {properties.map((property, index) => (
          <PropertyCard item={property} key={index} />
        ))}
      </div>
    </div>
  );
};

export default LatestProperties;
