import React from 'react';
import AdvancedSearchFilter from '../sharedComponents/AdvancedSearchFilter';
import ScrollToTop from '../sharedComponents/ScrollToTop';
import HeaderResponsive from './HeaderResponsive';
import HeaderMain from './HeaderMain';
import Translate from '../sharedComponents/Translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

function HeaderFilters({ sticky, pageType }) {
  return (
    <div className={`header-filters-app ${sticky ? 'sticky-header' : ''}`}>
      <ScrollToTop />
      <HeaderResponsive pageType={pageType} />
      <HeaderMain pageType={pageType} />
      <div className="filters-container bg-white">
        <div className="container">
          <AdvancedSearchFilter pageType={pageType} />
        </div>
      </div>
      <div className="collapse navbar-collapse expand-filters" id="realdeal_filters">
        <div className="container">
          <AdvancedSearchFilter pageType={pageType} />
        </div>
      </div>
      <div className="responsive-mobile-menu expand-filter-button">
        <button
          className="menu toggle-btn d-block d-lg-none text-center"
          data-toggle="collapse"
          data-target="#realdeal_filters"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faCaretDown} size="md" color="#b8a196" className="down" />
          <FontAwesomeIcon icon={faCaretUp} size="md" color="#b8a196" className="up" />
          <Translate textKey="home.filters.filters" />
        </button>
      </div>
    </div>
  );
}

export default HeaderFilters;
