import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const useDate = () => {
  const formatDate = (date) => {
    return dayjs(date).format('DD.MM.YYYY');
  };

  return {
    formatDate,
  };
};

export default useDate;
