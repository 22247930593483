import React from 'react';
import Navbar from '../globalComponents/Navbar';
import Footer from '../globalComponents/Footer';
import NotFound from '../globalComponents/NotFound';

function PageNotFound() {
  return (
    <div className="not-found-page-container">
      <Navbar />
      <NotFound />
      <Footer white />
    </div>
  );
}

export default PageNotFound;
