import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Translate from '../sharedComponents/Translate';
import { ReactComponent as Logo } from '../../svg/logo.svg';
import { AppContext } from '../../App.context';
import ScrollToTop from '../sharedComponents/ScrollToTop';

function Navbar() {
  const { appState, appActions } = useContext(AppContext);
  return (
    <nav className="navbar navbar-area navbar-expand-lg">
      <ScrollToTop />
      <div className="container nav-container">
        <div className="small-language-selector d-flex d-lg-none">
          <select
            value={appState.userLang}
            onChange={(event) => {
              appActions.changeUserLang(event.target.value);
            }}
          >
            <option value="en">EN</option>
            <option value="es">ES</option>
            <option value="de">DE</option>
          </select>
        </div>
        <div className="language-selector">
          <a
            data-testid="en-language-selector"
            className={appState.userLang === 'en' ? 'selected' : ''}
            onClick={() => appActions.changeUserLang('en')}
          >
            EN
          </a>
          <a
            data-testid="es-language-selector"
            className={appState.userLang === 'es' ? 'selected' : ''}
            onClick={() => appActions.changeUserLang('es')}
          >
            ES
          </a>
          <a
            data-testid="de-language-selector"
            className={appState.userLang === 'de' ? 'selected' : ''}
            onClick={() => appActions.changeUserLang('de')}
          >
            DE
          </a>
        </div>
        <div className="logo readeal-top">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="responsive-mobile-menu">
          <button
            className="menu toggle-btn d-block d-lg-none"
            data-toggle="collapse"
            data-target="#realdeal_main_menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-left" />
            <span className="icon-right" />
          </button>
        </div>
        <div className="collapse navbar-collapse" id="realdeal_main_menu">
          <Link to="/forms/contactUs" className="btn btn-primary">
            <Translate textKey="common.contactUs" />
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
