import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ReactComponent as BedIcon } from '../../svg/bed.svg';
import { ReactComponent as BathIcon } from '../../svg/bath.svg';
import { ReactComponent as ConstructedAreaIcon } from '../../svg/constructedArea.svg';
import { ReactComponent as PlotAreaIcon } from '../../svg/plotArea.svg';
import Translate from '../sharedComponents/Translate';
import formatThousands from 'format-thousands';
import { Button } from 'react-bootstrap';
import { XmlFeedContext } from '../../XmlFeed.context';

const PropertyCard = ({ item, showXmlButton, rowMode }) => {
  const history = useHistory();
  const imagePlaceholder = '/assets/img/hc/imagePlaceholder.png';
  const featureClasses = rowMode ? 'single-feature style-two' : 'single-feature';
  const detailsContainer = rowMode ? 'details details-container' : 'details-container';
  const details = rowMode ? 'details-wrap w-100' : 'details';
  const FormatValue = ({ value }) => <span>{value > 0 ? value : 'n/a'}</span>;

  return (
    <div className="property-card" onClick={() => history.push(`/property/${item.id}`)}>
      <div className={featureClasses}>
        {item.featured && <i className="icon-star fa fa-star" />}
        <div className={`${rowMode ? 'full-height' : ''} thumb`}>
          <img
            src={item.coverImgPath}
            alt={item.name}
            onError={(event) => (event.target.src = imagePlaceholder)}
          />
        </div>
        <div className={detailsContainer}>
          <div className={details}>
            <Link to={`/property/${item.id}`}>
              <h6 className="title readeal-top">{item.name}</h6>
            </Link>
            <div className="second-row">
              <span>{item.locationName ? item.locationName.toUpperCase() : ''}</span>
              <span className="price">
                {item.price > 0 ? (
                  <>{formatThousands(item.price, { separator: ',' })} €</>
                ) : (
                  <Translate textKey="properties.poa" />
                )}
              </span>
            </div>
            <div className="info-list">
              <div>
                <BedIcon />
                <FormatValue value={item.bedrooms} />
              </div>
              <div>
                <BathIcon />
                <FormatValue value={item.bathrooms} />
              </div>
              <div>
                <ConstructedAreaIcon />
                <FormatValue value={item.constructedAreaSize} />
                {item.constructedAreaSize > 0 && (
                  <>
                    m<sup>2</sup>
                  </>
                )}
              </div>
              <div>
                <PlotAreaIcon />
                <FormatValue value={item.areaSize} />
                {item.areaSize > 0 && (
                  <>
                    m<sup>2</sup>
                  </>
                )}
              </div>
            </div>
            {showXmlButton ? (
              <XmlFeedButton property={item} />
            ) : (
              <ul className="contact-list">
                <li>
                  <a
                    className="btn btn-primary"
                    href="tel:+34971708888"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <i className="fa fa-phone" />
                  </a>
                </li>
                <li>
                  <Link
                    className="btn btn-primary"
                    to={'/forms/contactUs'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <i className="fa fa-comment"></i>
                  </Link>
                </li>
                <li className="readeal-top">
                  <Link
                    className="btn btn-yellow"
                    to={`/property/${item.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Translate textKey="properties.viewDetails" />
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function XmlFeedButton({ property }) {
  const { state, actions } = useContext(XmlFeedContext);
  const selected = state.properties.some((selectedProperty) => selectedProperty.id == property.id);
  return selected ? (
    <Button
      block
      className="mt-3 btn-white"
      onClick={(e) => {
        e.stopPropagation();
        actions.removeProperty(property.id);
      }}
    >
      <Translate textKey={'xmlFeed.remove'} />
    </Button>
  ) : (
    <Button
      variant="primary"
      block
      className="mt-3"
      onClick={(e) => {
        e.stopPropagation();
        actions.addProperty(property);
      }}
    >
      <Translate textKey={'xmlFeed.add'} />
    </Button>
  );
}

export default PropertyCard;
