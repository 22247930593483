import React, { useContext } from 'react';
import { translate } from '../../data/languages/languageUtils';
import { AppContext } from '../../App.context';

function PaginationResultsText({ page, maxResults, totalResults }) {
  const { appState } = useContext(AppContext);

  return (
    <div className="results-text">
      {totalResults > 0 && (
        <>
          {totalResults < page * maxResults ? totalResults : page * maxResults}{' '}
          {translate('pagination.out', appState.userLang)} {totalResults}{' '}
          {translate('pagination.properties', appState.userLang)}
        </>
      )}
    </div>
  );
}

export default PaginationResultsText;
