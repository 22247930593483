import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Translate from '../sharedComponents/Translate';
import { AppContext } from '../../App.context';
import { ReactComponent as Logo } from '../../svg/logo.svg';
import SubscribeAlerts from '../sectionComponents/SubscribeAlerts';
import useHeader from '../../hooks/useHeader';

function HeaderMain({ pageType }) {
  const { links, createLink } = useHeader(pageType);
  const { appState, appActions } = useContext(AppContext);
  const {
    filters: { propertyTypes },
  } = appState;

  return (
    <div className="header-app container">
      <header>
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <nav className="nav-app">
          <div className="main-menu">
            {links.map((item, i) => (
              <Link key={i} className="item" to={createLink(propertyTypes, item.typeId)}>
                <Translate textKey={item.titleKey} />
              </Link>
            ))}
            <a className="item" href="https://www.helencummins.com/">
              Lifestyle
            </a>
          </div>
          <SubscribeAlerts buttonTextKey="forms.createAlert" ghostStyle={true} />
          <div className="languages">
            <a
              data-testid="en-language-selector"
              className={appState.userLang === 'en' ? 'selected' : ''}
              onClick={() => appActions.changeUserLang('en')}
            >
              EN
            </a>
            <a
              data-testid="es-language-selector"
              className={appState.userLang === 'es' ? 'selected' : ''}
              onClick={() => appActions.changeUserLang('es')}
            >
              ES
            </a>
            <a
              data-testid="de-language-selector"
              className={appState.userLang === 'de' ? 'selected' : ''}
              onClick={() => appActions.changeUserLang('de')}
            >
              DE
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default HeaderMain;
