import React, { useState } from 'react';
import Comment from '../sharedComponents/Comment';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Comments({ title, comments }) {
  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);
  const handleNextComment = () => {
    setCurrentCommentIndex((prevIndex) => (prevIndex + 1) % comments.length);
  };

  const handlePrevComment = () => {
    setCurrentCommentIndex((prevIndex) => (prevIndex - 1 + comments.length) % comments.length);
  };

  const currentComment = comments[currentCommentIndex];

  return (
    <div className="comments-container">
      <h3 className="title-underline">{title}</h3>
      <div className="carousel">
        <div className="control" onClick={handlePrevComment}>
          <FontAwesomeIcon icon={faCaretLeft} size="6x" />
        </div>
        <div className="comments">
          <Comment
            comment={`"${currentComment.comment}"`}
            imgUrl={currentComment.imgUrl}
            author={currentComment.author}
          />
        </div>
        <div className="control" onClick={handleNextComment}>
          <FontAwesomeIcon icon={faCaretRight} size="6x" />
        </div>
      </div>
    </div>
  );
}
