import React from 'react';
import { contactForm } from '../../api/postContactForm';
import ContactForm from '../sectionComponents/ContactForm';

function ContactUsScreen() {
  return (
    <ContactForm
      titleKey="forms.contactUsFormTitle"
      imageUrl="/assets/img/hc/contact/contactUs.jpeg"
      type={contactForm}
    />
  );
}

export default ContactUsScreen;
