import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '../sharedComponents/Translate';

function NotFound() {
  return (
    <div className="not-found-component">
      <div className="number">404</div>
      <div className="text">
        <Translate textKey="404.notFound" />
      </div>
      <div className="message">
        <Translate textKey="404.message" />
      </div>
      <Link to="/" className="btn btn-primary mb-2 mb-sm-0 mr-2">
        <Translate textKey="404.goHome" />
      </Link>
    </div>
  );
}

export default NotFound;
