import React, { useEffect, useState } from 'react';
import PaginationPages from '../sharedComponents/PaginationPages';
import PaginationResultsText from '../sharedComponents/PaginationResultsText';

function Pagination({ page, maxResults, totalResults, pageType }) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const pages = Math.ceil(totalResults / maxResults);
    setTotalPages(pages);
  }, [page]);

  return (
    <div className="pagination-component">
      {totalPages > 0 && (
        <div className="pagination-controls">
          <PaginationResultsText page={page} totalResults={totalResults} maxResults={maxResults} />
          <PaginationPages page={page} totalPages={totalPages} pageType={pageType} />
        </div>
      )}
    </div>
  );
}

export default Pagination;
