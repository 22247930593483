import React, { useEffect, useRef, useState } from 'react';
import getPropertiesFilter from './api/getPropertiesFilter';
import getProperties from './api/getProperties';
import getProperty from './api/getProperty';
import getBuilder from './api/getBuilder';
import { useHistory, useLocation } from 'react-router';

export const AppContext = React.createContext({});

const languagesApp = ['es', 'en', 'de'];
const userLangLong = navigator.language || navigator.userLanguage;
const userLangBrowser = userLangLong.substr(0, 2);
const userLang = languagesApp.includes(userLangBrowser) ? userLangBrowser : languagesApp[1];

export default function AppContextProvider({ children }) {
  const [appState, setAppState] = useState({
    sidebarOpened: false,
    userLang,
    filters: {
      minMaxBedrooms: { min: 0, max: 10 },
      minMaxPrice: { min: 500000, max: 10000000 },
    },
    properties: [],
    totalSearchProperties: 0,
    propertiesLoading: false,
    propertiesDetails: {},
    previousUrl: '',
    currentUrl: '',
  });
  const currentUrlRef = useRef('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function getInitialData() {
      const filters = await getPropertiesFilter(appState.userLang);
      updateState({ filters: setPropertiesFilter(appState.filters, filters) });
    }
    getInitialData();
  }, []);

  useEffect(() => {
    const previousUrl = currentUrlRef.current;
    currentUrlRef.current = window.location.href;
    updateState({ previousUrl, currentUrl: window.location.href });
  }, [location]);

  const appActions = {
    searchProperties: async (filter) => {
      updateState({ propertiesLoading: true });
      const { total, properties } = await getProperties(appState.userLang, filter);
      const newProperties = properties || [];
      const newState = {
        properties: newProperties,
        propertiesLoading: false,
        totalSearchProperties: total,
      };
      updateState(newState);
    },
    loadProperty: async (propertyId) => {
      updateState({ propertiesLoading: true });
      try {
        const property = await getProperty(propertyId);
        const propertiesDetails = {
          [propertyId]: property,
          ...appState.propertiesDetails,
        };
        updateState({ propertiesDetails, propertiesLoading: false });
        return { data: property, success: true };
      } catch (error) {
        const propertiesDetails = {
          [propertyId]: {},
          ...appState.propertiesDetails,
        };
        updateState({ propertiesDetails, propertiesLoading: false });
        return { error: error.response?.data, success: false };
      }
    },
    changeUserLang: async (newUserLang) => {
      if (location.pathname.startsWith('/property/')) {
        history.push('/');
      }
      updateState({ userLang: newUserLang });
      const filters = await getPropertiesFilter(newUserLang);
      updateState({ filters: setPropertiesFilter(appState.filters, filters) });
    },
    getProperties: async (lang, filter) => {
      return await getProperties(lang, filter);
    },
    getBuilder: async (lang) => {
      return await getBuilder(lang);
    },
  };

  function updateState(newState) {
    setAppState((previousState) => ({ ...previousState, ...newState }));
  }

  return <AppContext.Provider value={{ appState, appActions }}>{children}</AppContext.Provider>;
}

function setPropertiesFilter(currentFilters, propertiesFilters) {
  const filters = { ...currentFilters };
  filters.propertyFeatures = propertiesFilters.propertyFeatures?.sort(sortByName);
  filters.propertyLocations = propertiesFilters.propertyLocations?.sort(sortByName);
  filters.propertyRegions = propertiesFilters.propertyRegions?.sort(sortByName);
  filters.propertyTypes = propertiesFilters.propertyTypes;
  return filters;
}

const sortByName = (a, b) => a.name.localeCompare(b.name);
