import React from 'react';
import HeaderHome from './HeaderHome';
import AdvancedSearchFilter from '../sharedComponents/AdvancedSearchFilter';

function MainCover({ imageUrl, title }) {
  const inlineStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="cover-app jarallax" style={inlineStyle}>
      <div className="cover-inner-wrap">
        <div className="content-cover">
          <HeaderHome pageType={'properties'} />
          <h2 className="title">{title}</h2>
          <div className="search-filters-container">
            <div className="container">
              <AdvancedSearchFilter pageType={'properties'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainCover;
