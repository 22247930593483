import get from 'lodash.get';
import en from '../../data/languages/en.json';
import es from '../../data/languages/es.json';
import de from '../../data/languages/de.json';
import { useContext } from 'react';
import { AppContext } from '../../App.context';

const defaultLanguage = 'en';
const languages = {
  en,
  es,
  de,
};

function Translate({ textKey, uppercase = false }) {
  const { appState } = useContext(AppContext);
  const selectedLanguage = languages[appState.userLang] || languages[defaultLanguage];
  let text = get(selectedLanguage, textKey, textKey);
  if (uppercase) {
    text = text.toUpperCase();
  }
  return text;
}

export default Translate;
