import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { XmlFeedContext } from '../../XmlFeed.context';
import Translate from '../sharedComponents/Translate';

export default function XmlFeedStatus() {
  const { state, actions } = useContext(XmlFeedContext);
  const [modalMessage, setModalMessage] = useState();
  return (
    <>
      <div className="call-to-action-area pd-top-100 pb-5">
        <div className="container">
          <div className="call-to-action style-two">
            <div className="cta-content xml-feed">
              <div className="xml-feed-header">
                <h4 className="title">
                  <Translate textKey="xmlFeed.propertiesSelected" />
                </h4>
                <form>
                  <Button
                    className="btn-white"
                    onClick={async (e) => {
                      e.stopPropagation();
                      try {
                        await actions.generateXML();
                        setModalMessage(<Translate textKey={'xmlFeed.ready'} />);
                      } catch (e) {
                        setModalMessage(
                          <Translate textKey={'xmlFeed.error'} /> + ' ' + e.getMessage(),
                        );
                      }
                    }}
                  >
                    <Translate textKey={'xmlFeed.generate'} />
                    {state.generating ? (
                      <div
                        className="spinner"
                        role="progressbar"
                        aria-valuetext="Working…"
                        aria-busy="true"
                      ></div>
                    ) : null}
                  </Button>
                </form>
              </div>
              <div className="items">
                {state.properties.map((property, idx) => (
                  <div key={property.id} className="item">
                    <span>
                      <strong>{idx + 1}</strong> [{property.id}] {property.name}
                    </span>
                    <Button
                      className="ml-2 btn-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        actions.removeProperty(property.id);
                      }}
                      size="sm"
                    >
                      <Translate textKey={'xmlFeed.remove'} />
                    </Button>
                  </div>
                ))}
                {state.properties.length == 0 ? (
                  <p>
                    <Translate textKey={'xmlFeed.noPropertiesSelected'} />
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={!!modalMessage} onHide={() => setModalMessage(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate textKey={'xmlFeed.result'} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
      </Modal>
    </>
  );
}
