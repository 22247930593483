import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import validateTokenCode from './../../api/validateTokenCode';
import Translate from '../sharedComponents/Translate';

function AuthorizationForm({ onSubmit }) {
  const [invalidCode, setInvalidCode] = useState(false);
  const inputRef = useRef();

  async function validateCode() {
    const value = inputRef.current.value;
    try {
      await validateTokenCode(value);
      onSubmit({ isValidCode: true });
    } catch (error) {
      setInvalidCode(true);
      onSubmit({ isValidCode: false });
    }
  }

  return (
    <div className="authorization-form">
      <label>
        <Translate textKey="otp.addCode" />
      </label>
      <div className="rld-single-input">
        <input id="code" placeholder="000000" ref={inputRef} />
      </div>
      <Button
        onClick={() => {
          validateCode();
        }}
      >
        <Translate textKey="otp.check" />
      </Button>
      {invalidCode && (
        <h6 className="text-danger error-code">
          {' '}
          <Translate textKey="otp.invalidCode" />
        </h6>
      )}
    </div>
  );
}

export default AuthorizationForm;
