import React from 'react';

export default function Comment({ imgUrl, comment, author }) {
  return (
    <div className="comment-container">
      <div className="image-wrapper">
        <img src={imgUrl} />
      </div>
      <div className="comment-details">
        <div className="comment"> {comment} </div>
        <div className="author"> {author} </div>
      </div>
    </div>
  );
}
