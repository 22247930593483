import axios from 'axios';
import constants from '../constants';
import getProperty from './getProperty';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { defaultFilter } from '../components/sharedComponents/AdvancedSearchFilter';
import getProperties from './getProperties';
import en from '../data/languages/en.json';
import es from '../data/languages/es.json';
import de from '../data/languages/de.json';
import getPropertiesFilter from './getPropertiesFilter';

dayjs.extend(utc);
const translations = {
  en,
  es,
  de,
};

const languages = ['en', 'es', 'de'];
export default async function uploadXML(properties, lang) {
  const restOflanguages = languages.filter((language) => language != lang);
  await createAndUploadXML(properties, lang);
  for (const language of restOflanguages) {
    await createAndUploadXML(properties, language, true);
  }
}

async function createAndUploadXML(properties, lang, makeRequest = false) {
  const body = await createXML(properties, lang, makeRequest);
  const response = await axios.post(`${constants.apiUrl}/uploadXml`, {
    xml: body,
    lang: lang?.toUpperCase() || 'EN',
  });
  return response.data;
}

async function createXML(properties, lang, makeRequest) {
  const now = dayjs().utc().format('ddd, DD MMM YYYY HH:mm:ss ZZ');
  let propertiesXML = '';
  const filters = await getPropertiesFilter(lang);
  for (let property of properties) {
    if (makeRequest) {
      const { properties } = await getProperties(lang, { reference: property.reference });
      const translatedProperty = properties ? properties[0] : [];
      if (translatedProperty) {
        property = translatedProperty;
      } else {
        continue;
      }
    }
    const propertyDetails = await getProperty(property.id);
    const { id, typeId, name, coverImgPath } = propertyDetails;
    const propertyUrl = `https://www.mallorcaproperty.net/property/${id}`;
    const description = htmlToText(propertyDetails.description)?.substring(0, 155) + '...';
    const typeUrl = `https://www.mallorcaproperty.net/properties?filter=${encodeURIComponent(
      JSON.stringify({ ...defaultFilter, ...{ propertyTypes: [typeId] } }),
    )}`;
    const type = filters.propertyTypes?.find((propertyType) => propertyType.id == typeId);

    propertiesXML += `<item>
    <description>
      <![CDATA[
        <div style="border-bottom: 1px solid #CCC;padding-left:18px;padding-right:18px;padding-bottom:30px; margin-bottom:30px;">
        <a href="${typeUrl}" style="text-decoration:none" data-wpel-link="internal"><h3 style="text-decoration: underline; color: #775C50; font-family: Dosis, serif; font-size: 18px; font-style: normal; font-weight: normal; line-height: 125%;letter-spacing: normal; text-align: left;">${
      type?.name
    }</h3></a>
        <a href="${propertyUrl}" style="text-decoration:none;" data-wpel-link="internal"><h2 style="font-size: 18px;text-transform: uppercase;line-height: 125%;font-family: Dosis, serif; margin-top: 10px;margin-bottom: 15px; color: #775C50;">${name}</h2></a>
        <a href="${propertyUrl}" data-wpel-link="internal"><img src="${
      coverImgPath?.startsWith('http') ? coverImgPath : 'https:' + coverImgPath
    }" width="100%" alt="${name}" /></a>
        <p style="text-align:left;font-weight: 400;font-size:16px;line-height:26px;margin-bottom:15px;color:#696969;font-family: Dosis, serif;">${description}</p>
        <a style="color: #775C50;font-family: Dosis, serif;" href="${propertyUrl}" data-wpel-link="internal">${
      translations[lang].xmlFeed.readMore
    }</a>
        </div>
      ]]>  
    </description>
    <pubDate>${now}</pubDate>
    <guid>${propertyUrl}</guid>
  </item>`;
  }

  return `<?xml version="1.0"?>
  <rss version="2.0">
    <channel>
      <title>abcMallorca Newsletter</title>
      <link>http://www.abc-mallorca.com</link>
      <description>The latest news from abc-mallorca.com</description>
      <language>${lang}</language>
      <pubDate>${now}</pubDate>
      <lastBuildDate>${now}</lastBuildDate>
      <managingEditor>info@abc-mallorca.com (Helen Cummins)</managingEditor>
      ${propertiesXML}
    </channel>
  </rss>`;
}

function htmlToText(str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, 'text/html');
  return doc.body.innerText;
}
