import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { AppContext } from '../../App.context';
import { translate } from '../../data/languages/languageUtils';
import Translate from './Translate';
import useFilters from '../../hooks/useFilters';
import MinMaxSelect from './MixMaxSelect';
import Select from './Select';
import MultiSelectDropdown from './MultiSelectDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faSearch } from '@fortawesome/free-solid-svg-icons';

export const defaultFilter = {
  order: 'date',
  orderDirection: 'desc',
};

function AdvancedSearchFilter({ pageType }) {
  const location = useLocation();
  const { appState } = useContext(AppContext);
  const [formState, setFormState] = useState(defaultFilter);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const { pricesOptions, bedroomsOptions } = useFilters(appState.userLang);
  const selectPlaceholder = 'home.filters.select';
  const filters = appState.filters || {};

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filter = queryParams.get('filter')
      ? JSON.parse(queryParams.get('filter'))
      : defaultFilter;
    setFormState(filter);
  }, [location]);

  const FilterButtons = () => (
    <div className="filter-buttons">
      <Link
        className="btn btn-yellow search-button"
        to={`/${pageType}?filter=${encodeURIComponent(JSON.stringify(formState))}`}
      >
        <Translate textKey="home.filters.searchNow" />
      </Link>
      <Link
        to={`/${pageType}?filter=${encodeURIComponent(JSON.stringify(defaultFilter))}`}
        className="btn clear-button"
      >
        <Translate textKey="home.filters.clear" />
      </Link>
    </div>
  );

  return (
    <div className="search-filters">
      <div className="row-filters row">
        <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
          <MultiSelectDropdown
            placeholder={showOptionsSelected(
              getSelectedOptions(filters.propertyLocations, formState.propertyLocations),
              appState.userLang,
              selectPlaceholder,
            )}
            label={translate('home.filters.searchByLocation', appState.userLang)}
            icon={faCaretDown}
            options={filters.propertyLocations}
            selectedOptions={getSelectedOptions(
              filters.propertyLocations,
              formState.propertyLocations,
            )}
            onChange={(value) =>
              setFormState({ ...formState, propertyLocations: value.map((val) => val.id) })
            }
          />
        </div>
        <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
          <MultiSelectDropdown
            placeholder={showOptionsSelected(
              getSelectedOptions(filters.propertyTypes, formState.propertyTypes),
              appState.userLang,
              selectPlaceholder,
            )}
            label={translate('home.filters.propertyType', appState.userLang)}
            icon={faCaretDown}
            options={filters.propertyTypes}
            selectedOptions={getSelectedOptions(filters.propertyTypes, formState.propertyTypes)}
            onChange={(value) =>
              setFormState({ ...formState, propertyTypes: value.map((val) => val.id) })
            }
          />
        </div>
        <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
          {filters.minMaxPrice && (
            <MinMaxSelect
              options={pricesOptions}
              min={formState.price?.min}
              max={formState.price?.max}
              minLabel={translate('home.filters.minPrice', appState.userLang)}
              maxLabel={translate('home.filters.maxPrice', appState.userLang)}
              onChange={(value) => setFormState({ ...formState, price: value })}
            />
          )}
        </div>
        <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3 search-buttons">
          <FilterButtons />
        </div>
      </div>
      {filtersExpanded && (
        <div className="row-filters row">
          <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
            <MultiSelectDropdown
              placeholder={showOptionsSelected(
                getSelectedOptions(filters.propertyRegions, formState.propertyRegions),
                appState.userLang,
                selectPlaceholder,
              )}
              label={translate('home.filters.searchByRegion', appState.userLang)}
              icon={faCaretDown}
              options={filters.propertyRegions}
              selectedOptions={getSelectedOptions(
                filters.propertyRegions,
                formState.propertyRegions,
              )}
              onChange={(value) =>
                setFormState({ ...formState, propertyRegions: value.map((val) => val.id) })
              }
            />
          </div>
          <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
            {filters.minMaxBedrooms && (
              <Select
                options={bedroomsOptions}
                value={formState.bedrooms?.min}
                label={translate('home.filters.minBedrooms', appState.userLang)}
                onChange={(value) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    bedrooms: { min: value },
                  }));
                }}
              />
            )}
          </div>
          <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
            <MultiSelectDropdown
              placeholder={showOptionsSelected(
                getSelectedOptions(filters.propertyFeatures, formState.propertyFeatures),
                appState.userLang,
                selectPlaceholder,
              )}
              label={translate('home.filters.propertyFeatures', appState.userLang)}
              icon={faCaretDown}
              options={filters.propertyFeatures}
              selectedOptions={getSelectedOptions(
                filters.propertyFeatures,
                formState.propertyFeatures,
              )}
              onChange={(value) =>
                setFormState({ ...formState, propertyFeatures: value.map((val) => val.id) })
              }
            />
          </div>
          <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3">
            <Form.Control
              type="text"
              autoFocus
              placeholder={translate('home.filters.keywordOrReference', appState.userLang)}
              onChange={(e) => setFormState({ ...formState, reference: e.target.value })}
              value={formState.reference}
            />
          </div>
        </div>
      )}
      <div className="expand-filters row mt-2">
        <div
          className="expand-action"
          onClick={() => {
            setFiltersExpanded(!filtersExpanded);
          }}
        >
          <FontAwesomeIcon icon={faSearch} size="sm" color="#b8a196" />
          {translate(
            filtersExpanded ? 'home.filters.lessFilters' : 'home.filters.moreFilters',
            appState.userLang,
          )}
          <FontAwesomeIcon
            icon={filtersExpanded ? faCaretDown : faCaretUp}
            size="lg"
            color="#b8a196"
          />
        </div>
      </div>
      <div className="input-filter search-buttons-responsive col-lg-12">
        <FilterButtons />
      </div>
    </div>
  );
}

function showOptionsSelected(selectedFeatures, userLang, keyText) {
  let text = translate(keyText, userLang);
  if (selectedFeatures && selectedFeatures.length) {
    if (selectedFeatures.length === 1) {
      text = selectedFeatures[0].name;
    } else {
      text = selectedFeatures.length + ' ' + translate('home.filters.selected', userLang);
    }
  }
  return text;
}

function getSelectedOptions(allItems, itemsSelected) {
  return allItems && allItems.length > 0 && itemsSelected && itemsSelected.length > 0
    ? allItems?.filter((propertyFeature) =>
        itemsSelected.some((selectedId) => propertyFeature.id == selectedId),
      )
    : [];
}

export default AdvancedSearchFilter;
