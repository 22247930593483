import React from 'react';

const Magazines = ({ title, magazines }) => {
  return (
    <div className="magazines-container">
      <h3 className="title-underline">{title}</h3>
      <div className="magazines">
        {magazines.map((magazine, index) => (
          <div key={index} className="magazine">
            <img src={magazine.coverUrl} />
            <p>{magazine.title}</p>
            <a href={magazine.linkTo} className="btn btn-yellow">
              {magazine.linkTitle}
            </a>
          </div>
        ))}
      </div>
      <div className="square"></div>
    </div>
  );
};

export default Magazines;
