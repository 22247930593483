import React from 'react';
import { Link } from 'react-router-dom';

function Ads({ title, img, linkUrl, linkTitle }) {
  const inlineStyle = {
    backgroundImage: `url(${img})`,
  };

  return (
    <div className="ads call-to-action-area pd-top-70 pd-bottom-70">
      <div className="call-to-action" style={inlineStyle}>
        <div className="cta-content-flex">
          <h2 className="title">{title}</h2>
          <Link className="btn btn-primary with-border" to={linkUrl}>
            {linkTitle}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Ads;
