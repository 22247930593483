import React, { useState } from 'react';
import Navbar from '../globalComponents/Navbar';
import { Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import removeSubscriberEmail from '../../api/removeSubscriberEmail';
import Translate from '../sharedComponents/Translate';

function UnsubscribeScreen() {
  const { subscriberId } = useParams();
  const [success, setSuccess] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const history = useHistory();

  const removeSubscriber = async () => {
    setSuccess(true);
    try {
      await removeSubscriberEmail(subscriberId);
      redirectToHome();
    } catch (error) {
      setSuccess(false);
      setErrorMessage(true);
      const errorCode = error.response?.data?.code;
      if (errorCode === 'gf_already_trashed') {
        redirectToHome();
      }
    }
  };

  function redirectToHome() {
    setRedirect(true);
    setTimeout(() => {
      setRedirect(false);
      history.push('/');
    }, 1500);
  }

  return (
    <>
      <Navbar />
      <div className="unsubscribe-message">
        {redirect ? (
          <>
            <h5>
              {errorMessage ? (
                <Translate textKey="errors.alreadyUnsubscribe" />
              ) : (
                <Translate textKey="unsubscribe.success" />
              )}
            </h5>
            <h6>
              <Translate textKey="unsubscribe.redirect" />
            </h6>
          </>
        ) : (
          <>
            <h4>
              <Translate textKey="unsubscribe.title" />
            </h4>
            <h5>
              <Translate textKey="unsubscribe.message" />
            </h5>
            <Button variant="primary" onClick={removeSubscriber}>
              <Translate textKey="unsubscribe.confirm" />
            </Button>
            {!success && (
              <h6>
                <Translate textKey="errors.server" />
              </h6>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default UnsubscribeScreen;
