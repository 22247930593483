import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../App.context';
import formatThousands from 'format-thousands';
import Translate from '../sharedComponents/Translate';
import NotFound from '../globalComponents/NotFound';
import { translate } from '../../data/languages/languageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FacebookProvider, Share } from 'react-facebook';
import Lightbox from 'react-image-lightbox';
import { ReactComponent as BedIcon } from '../../svg/bed.svg';
import { ReactComponent as BathIcon } from '../../svg/bath.svg';
import { ReactComponent as ConstructedAreaIcon } from '../../svg/constructedArea.svg';
import { ReactComponent as PlotAreaIcon } from '../../svg/plotArea.svg';
import { ReactComponent as CheckIcon } from '../../svg/check.svg';
import CallToActionContact from './CallToActionContact';
import PropertyDetailsForm from './PropertyDetailsForm';
import PropertyDetailsBottomForm from './PropertyDetailsBottomForm';
import useDate from '../../hooks/useDate';

const googleMapsAPIKey = 'AIzaSyAPo_hpOnUyPO4HIM5v9mCxoCcuRuBDj8k';
const imagePlaceholder = '/assets/img/hc/detailPlaceholder.png';

function PropertyDetails() {
  const context = useContext(AppContext);
  const [propertyDetails, setPropertyDetails] = useState();
  const [propertyNotFound, setPropertyNotFound] = useState(false);
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(-1);
  const [saleTitle, setSaleTitle] = useState('');
  const { formatDate } = useDate();
  const pathParams = useParams();

  const fetchProperty = useCallback(async () => {
    const { appState, appActions } = context;
    if (appState && pathParams.id) {
      const propertyId = parseInt(pathParams.id);
      const currentProperty = appState.propertiesDetails[propertyId];
      if (currentProperty) {
        setPropertyDetails(currentProperty);
      } else if (!appState.propertiesLoading) {
        const { data, success } = await appActions.loadProperty(pathParams.id);
        if (success) {
          setPropertyDetails(data);
          const textSale = data.locationName
            ? `${data.type} ${translate('propertyDetails.sale', context.appState.userLang)} ${
                data.locationName
              }`
            : `${data.type}`;
          setSaleTitle(textSale);
        } else {
          setPropertyNotFound(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    fetchProperty();
  }, [pathParams]);

  useEffect(() => {
    if (propertyDetails && propertyDetails.photos && propertyDetails.photos.length > 1) {
      createCarousel();
    }
  }, [propertyDetails]);

  const featureNames = context.appState?.filters?.propertyFeatures;
  const userLang = context.appState.userLang;
  const propertyLink = window.location.href;
  const photos = propertyDetails ? propertyDetails.photos : [];

  return (
    <>
      {propertyDetails ? (
        <div className="property-details-area bg-white">
          <div className="bg-gray pd-bottom-90">
            <div className="container pd-top-70">
              <div className="row ">
                <div className="property-title col-xl-12 col-lg-12">
                  <h3>{propertyDetails.name}</h3>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <div className="property-details-slider">
                    {photos.map((photo, idx) => (
                      <div className="item" key={idx}>
                        <div className="thumb">
                          <img
                            src={photo}
                            alt={`Slider: ${propertyDetails.name} - Photo ${idx}`}
                            onError={(event) => (event.target.src = imagePlaceholder)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-9 col-lg-9">
                  <div className="property-details-slider-info">
                    <h3>{saleTitle}</h3>
                    <span className="price">{getPrice(propertyDetails, userLang)}</span>
                  </div>
                  <div className="row no-gutters property-details-slider-numbers">
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <div className="single-property-info">
                        <h5>
                          <Translate textKey="propertyDetails.bedrooms" />
                        </h5>
                        {propertyDetails.bedrooms > 0 ? (
                          <p>
                            <BedIcon />
                            {propertyDetails.bedrooms}
                          </p>
                        ) : (
                          <p>n/a</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <div className="single-property-info">
                        <h5>
                          <Translate textKey="propertyDetails.bathrooms" />
                        </h5>
                        {propertyDetails.bathrooms > 0 ? (
                          <p>
                            <BathIcon />
                            {propertyDetails.bathrooms}
                          </p>
                        ) : (
                          <p>n/a</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <div className="single-property-info">
                        <h5>
                          <Translate textKey="propertyDetails.constructed" />
                        </h5>
                        {propertyDetails.constructedAreaSize > 0 ? (
                          <>
                            <p>
                              <ConstructedAreaIcon />
                              {formatThousands(propertyDetails.constructedAreaSize, {
                                separator: '.',
                              })}{' '}
                              m<sup>2</sup>
                            </p>
                          </>
                        ) : (
                          <p>n/a</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <div className="single-property-info">
                        <h5>
                          <Translate textKey="propertyDetails.plotSize" />
                        </h5>
                        {propertyDetails.areaSize > 0 ? (
                          <p>
                            <PlotAreaIcon />
                            {formatThousands(propertyDetails.areaSize, { separator: '.' })} m
                            <sup>2</sup>
                          </p>
                        ) : (
                          <p>n/a</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters mt-5">
                    <div className="col-md-3 col-sm-12 mb-2">
                      <div className="single-property-info centered">
                        <h5>
                          <Translate textKey="propertyDetails.createdOn" />
                        </h5>
                        <p>{formatDate(propertyDetails.createdOn)}</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 mb-2">
                      <div className="single-property-info centered">
                        <h5>
                          <Translate textKey="propertyDetails.reference" />
                        </h5>
                        <p>ABC-{propertyDetails.id}</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 mb-2">
                      <div className="single-property-info centered">
                        <h5>
                          <Translate textKey="propertyDetails.type" />
                        </h5>
                        <p>{propertyDetails.type}</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 mb-2">
                      <div className="single-property-info centered">
                        <h5>
                          <Translate textKey="propertyDetails.location" />
                        </h5>
                        <p>{propertyDetails.locationName}</p>
                      </div>
                    </div>
                  </div>
                  <div className="property-info mb-5"></div>
                  <div className="property-news-single-card border-bottom-yellow">
                    <h4>
                      <Translate textKey="propertyDetails.description" />
                    </h4>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: propertyDetails.description }}
                    ></div>
                  </div>
                  {propertyDetails.features.length > 0 && (
                    <div className="property-news-single-card border-bottom-yellow">
                      <h4>
                        <Translate textKey="propertyDetails.features" />
                      </h4>
                      <ul className="rld-list-style ml-4">
                        <div className="row feature-icons">
                          {propertyDetails.features.map((featureId) => (
                            <div key={featureId} className="col-sm-6 col-md-4 mt-3">
                              <li>
                                <CheckIcon />{' '}
                                {featureNames?.find((feature) => feature.id === featureId).name}
                              </li>
                            </div>
                          ))}
                        </div>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="col-xl-3 col-lg-3">
                  <PropertyDetailsForm
                    propertyReference={propertyDetails.reference}
                    propertyName={propertyDetails.name}
                    agency={propertyDetails.agency}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pd-top-70">
              <div className="col-lg-12">
                <div className="property-news-single-card style-two border-bottom-yellow">
                  <h4>
                    <Translate textKey="propertyDetails.gallery" />
                  </h4>
                  <div className="row">
                    {photos.map((photo, idx) => (
                      <div className="col-lg-4 col-md-6 col-sm-6 mb-4" key={idx}>
                        <img
                          className="pointer"
                          src={photo}
                          alt={`${propertyDetails.name} - Photo ${idx}`}
                          onClick={() => {
                            setSelectedGalleryItem(idx);
                          }}
                          onError={(event) => (event.target.src = imagePlaceholder)}
                        />
                      </div>
                    ))}
                  </div>
                  {selectedGalleryItem >= 0 && (
                    <Lightbox
                      mainSrc={photos[selectedGalleryItem]}
                      nextSrc={photos[(selectedGalleryItem + 1) % photos.length]}
                      prevSrc={photos[(selectedGalleryItem + photos.length - 1) % photos.length]}
                      onCloseRequest={() => setSelectedGalleryItem(-1)}
                      onMovePrevRequest={() =>
                        setSelectedGalleryItem(
                          (selectedGalleryItem + photos.length - 1) % photos.length,
                        )
                      }
                      onMoveNextRequest={() =>
                        setSelectedGalleryItem((selectedGalleryItem + 1) % photos.length)
                      }
                    />
                  )}
                </div>
                <div className="property-news-single-card style-two border-bottom-yellow">
                  <h4 className="mb-5">
                    <Translate textKey="propertyDetails.locationMap" />
                  </h4>
                  <iframe
                    src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsAPIKey}&q=${propertyDetails.locationName},Mallorca`}
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </div>
                <div className="property-news-single-card mb-0">
                  <div className="share-icons">
                    <h4>
                      <Translate textKey="propertyDetails.share" />
                    </h4>
                    <div className="icons-list">
                      <FacebookProvider appId="591521958804617">
                        <Share href={propertyLink}>
                          {({ handleClick }) => (
                            <a href="" className="share-icon" onClick={handleClick}>
                              <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                          )}
                        </Share>
                      </FacebookProvider>
                      <a
                        href={`whatsapp://send?text=${encodeURIComponent(
                          translate('propertyDetails.shareText', userLang) + ' ' + propertyLink,
                        )}`}
                        className="share-icon"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </a>
                      <a
                        href={`mailto:?body=${encodeURIComponent(
                          translate('propertyDetails.shareText', userLang) + ' ' + propertyLink,
                        )}`}
                        className="share-icon"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </a>
                      <a
                        href=""
                        onClick={() => {
                          window.print();
                        }}
                        className="share-icon"
                      >
                        <FontAwesomeIcon icon={faPrint} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="property-news-single-card mb-0">
                  <PropertyDetailsBottomForm
                    propertyReference={propertyDetails.reference}
                    propertyName={propertyDetails.name}
                    agency={propertyDetails.agency}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!propertyNotFound && (
            <div
              className="spinner"
              role="progressbar"
              aria-valuetext="Working…"
              aria-busy="true"
            ></div>
          )}
        </>
      )}
      {propertyNotFound && <NotFound />}
      <div className="pd-top-100">
        <CallToActionContact />
      </div>
    </>
  );
}

function createCarousel() {
  if (window.jQuery) {
    const $pdsCarousel = window.jQuery('.property-details-slider');
    if ($pdsCarousel.length > 0 && $pdsCarousel.owlCarousel) {
      $pdsCarousel.owlCarousel({
        loop: true,
        autoplay: false,
        autoPlayTimeout: 1000,
        dots: true,
        nav: true,
        smartSpeed: 1500,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        items: 1,
      });
    }
  }
}

function getPrice(propertyDetails, userLang) {
  return propertyDetails.price > 0
    ? `${formatThousands(propertyDetails.price, { separator: ',' })} €`
    : translate('properties.poa', userLang);
}

export default PropertyDetails;
