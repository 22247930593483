import React, { useState, useEffect } from 'react';
import HeaderFilters from './HeaderFilters';

function MainHeader({ scrollDownPosition }) {
  const [showHeaderFilter, setShowHeaderFilter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowHeaderFilter(scrollPosition > scrollDownPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`main-header ${showHeaderFilter ? 'slide' : 'unslide'}`}>
      <HeaderFilters pageType="properties" />
    </div>
  );
}

export default MainHeader;
