import React from 'react';
import { translate } from '../../data/languages/languageUtils';

function NameFields({ formValues, setFormValue, userLang }) {
  return (
    <>
      <div className="rld-single-input">
        <input
          type="text"
          placeholder={translate('propertyDetails.firstName', userLang)}
          value={formValues.firstName}
          onChange={setFormValue('firstName')}
          required
        />
      </div>
      <div className="rld-single-input">
        <input
          type="text"
          placeholder={translate('propertyDetails.lastName', userLang)}
          value={formValues.lastName}
          onChange={setFormValue('lastName')}
          required
        />
      </div>
    </>
  );
}

export default NameFields;
